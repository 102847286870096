import React from "react";
import { usePosition } from "./usePosition";

const UsePositionDemo = () => {
  const { lat, lng, hash, err } = usePosition();

  return (
    <div>
      <code>
        latitude: {lat}
        <br />
        longitude: {lng}
        <br />
        geohash: {hash}
        <br />
        error: {err}
      </code>
    </div>
  );
};

export default UsePositionDemo;
