import geohash from "ngeohash";

export const getGeohashRange = (
  hash,
  distance // miles
) => {
  const { latitude, longitude } = geohash.decode(hash);

  const lat = 0.0144927536231884; // degrees latitude per mile
  const lon = 0.0181818181818182; // degrees longitude per mile

  const lowerLat = latitude - lat * distance;
  const lowerLon = longitude - lon * distance;

  const upperLat = latitude + lat * distance;
  const upperLon = longitude + lon * distance;

  const lower = geohash.encode(lowerLat, lowerLon);
  const upper = geohash.encode(upperLat, upperLon);

  return {
    lower,
    upper,
  };
};

export const getDistance = (hash1, hash2, unit) => {
  if (hash1 === hash2) {
    return 0;
  } else {
    const pos1 = geohash.decode(hash1);
    const pos2 = geohash.decode(hash2);
    const lat1 = pos1.latitude;
    const lon1 = pos1.longitude;
    const lat2 = pos2.latitude;
    const lon2 = pos2.longitude;

    const radlat1 = (Math.PI * lat1) / 180;
    const radlat2 = (Math.PI * lat2) / 180;
    const theta = lon1 - lon2;
    const radtheta = (Math.PI * theta) / 180;
    let dist =
      Math.sin(radlat1) * Math.sin(radlat2) +
      Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
    if (dist > 1) {
      dist = 1;
    }
    dist = Math.acos(dist);
    dist = (dist * 180) / Math.PI;
    dist = dist * 60 * 1.1515;
    if (unit === "K") {
      dist = dist * 1.609344;
    }
    if (unit === "N") {
      dist = dist * 0.8684;
    }
    return dist;
  }
};
