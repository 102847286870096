import React from "react";
import logo from "./favicon-196.png";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { isEmpty, isLoaded } from "react-redux-firebase";

const Home = props => {
  //TODO
  // const artistsPlaying = [];
  const auth = useSelector(state => state.firebase.auth);
  const profile = useSelector(state => state.firebase.profile);
  const history = useHistory();
  const sortedAry = useSelector(state => state.artists.sortedAry);

  const isArtist =
    (profile &&
      profile.token &&
      profile.token.claims &&
      profile.token.claims.artist &&
      true) ||
    false;

  return (
    <div className="Home-Container">
      {/* <img src="./favicon-196.png" alt="" /> */}
      <img src={logo} alt="" />
      <h1>Scroll Player</h1>
      <h6>Version {global.appVersion} &copy;2021 Pat. Pend.</h6>
      <p>Presenting your audio files and music xml leadsheets together</p>
      <div>
        <button
          className="App-navbutton"
          onClick={() => history.push("About" + window.location.search)}
        >
          Learn More
        </button>
      </div>
      {isLoaded(auth) && (
        <>
          {isEmpty(auth) ||
          (window.location.search.indexOf("requestDemo=true") >= 0 &&
            window.location.search.indexOf("anonDisplayName=") < 0) ? (
            <div>
              <button
                className="App-navbutton"
                onClick={() => history.push("login" + window.location.search)}
              >
                Sign In
              </button>
            </div>
          ) : (
            <div>
              <button
                className="App-navbutton"
                onClick={() =>
                  history.push("/preferences" + window.location.search)
                }
              >
                Preferences
              </button>
            </div>
          )}
          <div>
            <button
              className="App-navbutton"
              onClick={() => {
                // if (window.location.search.indexOf("requestDemo=true") >= 0) {
                //   console.log(profile.userId)
                //   history.push(
                //     `/requests/${profile.userId}/${window.location.search}`
                //   );
                // } else {
                history.push(
                  `/requests${
                    sortedAry && sortedAry.length > 0
                      ? "/" + sortedAry[0].artistId
                      : ""
                  }${window.location.search}`
                );
                // }
              }}
            >
              Requests
            </button>
          </div>
          {isArtist && window.location.search.indexOf("requestDemo=true") < 0 && (
            <div>
              <button
                className="App-navbutton"
                onClick={() => history.push("/mytracks")}
              >
                My Songs
              </button>
            </div>
          )}
        </>
      )}
      <div>&nbsp;</div>
    </div>
  );
};

export default Home;
