import React, { Suspense, lazy } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { useSelector, connect } from "react-redux";
import {
  isEmpty,
  useFirebase,
  useFirestore,
  useFirestoreConnect,
  isLoaded,
} from "react-redux-firebase";
import { usePosition } from "./usePosition";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

import "./App.css";
import "./Loading.css";
import packageJson from "../package.json";
import Spinner from "./Spinner";
import Home from "./Home";
import About from "./About";
import Login from "./Login";
import Preferences from "./Preferences";
// import MyTracks from "./MyTracks";
// import Requests from "./Requests";
import { getDistance } from "./geoUtils";
import { checkShow, roughSizeOfObject, sortAry } from "./utils";
import { stripePublicKey } from "./config";

import UsePositionDemo from "./UsePositionDemo";

global.qfile = {};

global.roughSizeOfObject = roughSizeOfObject;

const stripePromise = loadStripe(stripePublicKey);

const kaInterval = 3 * 60 * 60 * 1000;
// const kaInterval = 60 * 1000;
const uiaInterval = 15 * 60 * 1000; //15 minutes

const MyTracks = lazy(() => import("./MyTracks"));
const Requests = lazy(() => import("./Requests"));

global.appVersion = packageJson.version;

let gh = "";

const App = props => {
  const loadArtists = props.loadArtists;
  const isServiceWorkerInitialized = useSelector(
    state => state.swState.serviceWorkerInitialized
  );
  const isServiceWorkerUpdated = useSelector(
    state => state.swState.serviceWorkerUpdated
  );
  const serviceWorkerRegistration = useSelector(
    state => state.swState.serviceWorkerRegistration
  );

  const [showSWInit, setShowSWInit] = React.useState(
    isServiceWorkerInitialized
  );
  const [showSWUpdated, setShowSWUpdated] = React.useState(
    isServiceWorkerUpdated
  );

  const [withTutorial, setWithTutorial] = React.useState(false);
  const [withSecondApp, setWithSecondApp] = React.useState(false);
  React.useEffect(() => {
    checkShow(withTutorial, "App-Split-Container");
  }, [withTutorial]);
  React.useEffect(() => {
    checkShow(withSecondApp, "App-iframe-container");
  }, [withSecondApp]);

  const auth = useSelector(state => state.firebase.auth);
  const profile = useSelector(state => state.firebase.profile);
  const uid = auth.uid;

  // global.uid = uid;

  const isArtist =
    (profile &&
      profile.token &&
      profile.token.claims &&
      profile.token.claims.artist &&
      true) ||
    false;

  const isAdmin =
    (profile &&
      profile.token &&
      profile.token.claims &&
      profile.token.claims.admin &&
      true) ||
    false;

  const isAnon = auth && auth.isAnonymous;

  const isTrial = (profile && profile.isTrial && true) || false;

  const [showAcceptTerms, setShowAcceptTerms] = React.useState(false);

  React.useEffect(() => {
    if (profile && !profile.isEmpty && profile.isLoaded) {
      if (profile.isArtist && !profile.acceptedTerms) {
        setShowAcceptTerms(true);
      } else {
        setShowAcceptTerms(false);
      }
    }
  }, [profile]);

  React.useEffect(() => {
    setShowSWInit(isServiceWorkerInitialized);
  }, [isServiceWorkerInitialized]);

  React.useEffect(() => {
    setShowSWUpdated(isServiceWorkerUpdated);
  }, [isServiceWorkerUpdated]);

  const firebase = useFirebase();
  const firestore = useFirestore();

  const callTransferUserContent =
    firebase &&
    firebase.functions &&
    firebase.functions().httpsCallable("transferUserContent");

  React.useEffect(() => {
    if (isAdmin) {
      if (!global.testFunctionsLoaded) {
        console.log("loading test functions");
        global.promoteUser = (role, uid) => {
          firestore
            .collection("subscriptionRequests")
            .add({
              role: role,
              token: false,
              uid: uid,
            })
            .catch(err => {
              console.error(err);
            });
        };

        // global.updateProfileWithNewSet = uid => {
        //   firestore
        //     .collection("users")
        //     .doc(uid)
        //     .update({ sets: global.newSets })
        //     .then(() => {
        //       console.log("update complete");
        //     });
        // };

        global.getSubStatus = async s => {
          const qss = await firestore
            .collectionGroup("private")
            .where("subscription_id", "==", s)
            .get();

          let ss = "";

          if (qss.isEmpty) {
            ss = "no users found for this subscription_id";
          } else if (qss.size > 1) {
            ss = "multiple users found for this subscription_id";
          } else {
            ss = qss.docs[0].data().subscription_status;
          }

          // let ss = qss.docsx[0].data().subscription_status;

          // .then(querySnapShot => {
          //   let ss = "";
          //   querySnapShot.forEach(doc => {
          //     if (ss === "") {
          //       ss = doc.data().subscription_status || "";
          //       return;
          //     }
          //     // console.log(doc.id, " => ", doc.data());
          //   });
          console.log("ss:", ss);
          // console.log("ss:", querySnapShot[0].data().subscription_status);
          // });
        };

        // global.testRemoveField = async () => {
        //   const db = firestore;
        //   const aaDoc = await db.doc(`artists/All Artists`).get();
        //   console.log(aaDoc.data());
        //   const newAaDoc = { ...aaDoc.data() };
        //   delete newAaDoc["zlQWdbxG8fYfWL10XeHz3LZvknQ2"];
        //   console.log(newAaDoc);
        //   await db.doc(`artists/All Artists`).set(newAaDoc);
        // };

        global.copyUserContent = async data => {
          const result = await callTransferUserContent(data);
          console.log(result);
        };
        global.spillAboutDocumentContent = async doc => {
          const docData = await firestore
            .collection("documents")
            .doc(doc)
            .get()
            .then(d => {
              return d.data();
            });
          console.log("get function returned", docData);
          global.docData = docData;
        };
        global.setAboutDocument = async doc => {
          firestore.collection("documents").doc(doc).set(global.docData);
        };
        global.testFunctionsLoaded = true;
      }
    } else {
      global.promoteUser = null;
      // global.updateProfileWithNewSet = null;
      global.getSubStatus = null;
      global.copyUserContent = null;
      global.spillAboutDocumentContent = null;
      global.setAboutDocument = null;
    }
  }, [callTransferUserContent, firestore, isAdmin]);

  //timer to keep trials alive
  React.useEffect(() => {
    const kaFunction = () => {
      const d = new Date();
      console.log("updating subscription active: " + d.toISOString());
      firestore
        .collection("subscribers")
        .doc(uid)
        .update({ active: d.toISOString() });
    };
    if (uid && !isAdmin && isTrial && isArtist) {
      console.log("setting keepActive interval for trial");
      const keepActive = setInterval(kaFunction, kaInterval);
      kaFunction();
      return () => {
        clearInterval(keepActive);
      };
    }
  }, [uid, firestore, isAdmin, isTrial, isAnon, isArtist]);

  // I want to know what users are active...
  // I think we just do an active field update every 15 minutes I guess...
  React.useEffect(() => {
    const userIsActive = () => {
      const d = new Date();
      console.log("updating profile active: " + d.toISOString());
      firestore
        .collection("users")
        .doc(uid)
        .update({ active: d.toISOString() });
    };
    if (uid && profile.userId) {
      const uiaFunctionInterval = setInterval(userIsActive, uiaInterval);
      userIsActive();
      return () => {
        clearInterval(uiaFunctionInterval);
      };
    }
  }, [firestore, profile.userId, uid]);

  //you only get one shot at updating your location?
  // const [userLocationUpdated, setUserLocationUpdated] = React.useState(false);

  // useFirestoreConnect({
  //   collection: `users${auth && auth.uid ? `/${auth.uid}/private` : ``}`,
  //   storeAs: "userPrivate",
  // });

  useFirestoreConnect(
    auth &&
      auth.uid && {
        collection: `users/${auth.uid}/private`,
        storeAs: "userPrivate",
      }
  );

  const userPrivate = useSelector(
    state => state.firestore.data.userPrivate || {}
  );

  const [anonDisplayName, setAnonDisplayName] = React.useState("");

  React.useEffect(() => {
    if (
      userPrivate &&
      profile &&
      auth.uid &&
      ((profile.email && profile.email !== "") ||
        (profile.isLoaded && profile.email === undefined))
    ) {
      // if (profile && profile.email && profile.email !== "") {
      //move the email to the private collection and put the userId field in
      console.log("updating profile...");
      firestore
        .doc(`/users/${auth.uid}/private/info`)
        .set(
          { email: profile.email || auth.email, uid: auth.uid },
          { merge: true }
        )
        .then(() => {
          firestore
            .collection("users")
            .doc(auth.uid)
            .set(
              {
                email: "",
                userId: auth.uid,
                isAnon,
                displayName:
                  profile.displayName ||
                  auth.displayName ||
                  auth.email ||
                  anonDisplayName ||
                  "Anonymous User",
              },
              { merge: true }
            );
        });
    }
  }, [
    anonDisplayName,
    auth.displayName,
    auth.email,
    auth.uid,
    firestore,
    isAnon,
    profile,
    userPrivate,
  ]);

  // const [userAllowGeo, setUserAllowGeo] = React.useState(false);

  // const [coords, setCoords] = React.useState()

  React.useEffect(() => {}, []);

  const coords = usePosition({
    userAcceptedTerms:
      profile && !profile.isEmpty && profile.isLoaded && profile.acceptedTerms,
  });

  React.useEffect(() => {
    // console.log(coords);
    if (
      profile &&
      !profile.isEmpty &&
      profile.isLoaded &&
      coords &&
      coords.lat &&
      coords.lng &&
      coords.hash &&
      uid &&
      true
    ) {
      const hash = coords.hash;

      if (
        gh !== hash &&
        (profile.geohash === undefined || profile.geohash !== hash)
      ) {
        const d = new Date();
        const ts = d.toISOString();
        console.log(ts, "updating user profile with a new geohash:", hash);
        gh = hash;
        try {
          firestore
            .collection("users")
            .doc(auth.uid)
            .update({ geohash: hash })
            .then(() => {
              // setUserLocationUpdated(true);
            });
        } catch (error) {
          console.log(error);
        }
      } else {
        // setUserLocationUpdated(true);
      }

      // setUserLocationUpdated(true);
    }
  }, [auth.uid, coords, firestore, isArtist, profile, uid]);

  useFirestoreConnect({
    collection: `artists`,
    where: [["All Artists", "==", true]],
    storeAs: "allArtistsDoc",
  });

  const allArtists = useSelector(
    state => state.firestore.data.allArtistsDoc || {}
  );

  React.useEffect(() => {
    if (
      isLoaded(profile) &&
      !isEmpty(profile) &&
      isLoaded(auth) &&
      !isEmpty(auth) &&
      isLoaded(allArtists) && //perhaps
      !isEmpty(allArtists) && //perhaps
      // userLocationUpdated && //we don't need to do this unless this has been done
      true
    ) {
      const newAllArtists = { ...allArtists["All Artists"] };
      const thisArtist = newAllArtists[auth.uid];
      if (profile.artist) {
        const updates = {};
        updates[auth.uid] = {};
        updates[auth.uid].artistId = auth.uid;
        updates[auth.uid].geohash = profile.geohash;
        // updates[auth.uid].continuePlay = continuePlay;
        updates[auth.uid].searchString =
          profile.displayName.toLowerCase() +
          (profile.artistDescription !== undefined
            ? " " + profile.artistDescription.toLowerCase()
            : "");

        if (
          !thisArtist ||
          updates[auth.uid].geohash !== thisArtist.geohash ||
          updates[auth.uid].searchString !== thisArtist.searchString
        ) {
          // console.log(thisArtist);
          // console.log(updates[auth.uid]);
          if (!thisArtist) {
            console.log("adding artist to doc");
          } else {
            if (updates[auth.uid].geohash !== thisArtist.geohash) {
              const d = new Date();
              const ts = d.toISOString();
              console.log(
                ts,
                "updating All Artist doc geohash: " +
                  thisArtist.geohash +
                  "=>" +
                  updates[auth.uid].geohash
              );
            }
            if (updates[auth.uid].searchString !== thisArtist.searchString) {
              console.log(
                "updating All Artist doc searchString: " +
                  thisArtist.searchString +
                  "=>" +
                  updates[auth.uid].searchString
              );
            }
          }
          firestore
            .collection("artists")
            .doc("All Artists")
            .set(updates, { merge: true });
        }
      } else {
        if (thisArtist !== undefined) {
          console.log("removing user from All Artists");
          delete newAllArtists[auth.uid];
          firestore.collection("artists").doc("All Artists").set(newAllArtists);
          //   .then(d => {
          //     console.log("All Artists: ", d.data());
          //   });
        }
      }
    }
  }, [allArtists, auth, firestore, profile]);

  React.useEffect(() => {
    if (isLoaded(allArtists) && !isEmpty(allArtists)) {
      const d = new Date().valueOf() - 8 * 60 * 60 * 1000;
      const newArtistOrderAry = Object.entries(allArtists["All Artists"])
        .filter(
          ([k, v]) =>
            v.geohash !== undefined && k !== "All Artists" && k !== "artistId"
        )
        .map(([k, v]) => ({
          ...v,
          distance: getDistance(profile.geohash || v.geohash, v.geohash, "N"),
          sortIndex:
            getDistance(profile.geohash || v.geohash, v.geohash, "N") -
            (v.continuePlay && v.active >= d ? 0.1 : 0),
        }));

      const sortedAry = sortAry(newArtistOrderAry, "sortIndex");

      loadArtists(sortedAry);
    }
  }, [allArtists, loadArtists, profile.geohash]);

  // setTimeout(() => {
  //   setShowSWInit(false);
  // }, 5000);

  const updateServiceWorker = () => {
    const registrationWaiting = serviceWorkerRegistration.waiting;
    if (registrationWaiting) {
      registrationWaiting.postMessage({ type: "SKIP_WAITING" });
      registrationWaiting.addEventListener("statechange", e => {
        if (e.target.state === "activated") {
          window.location.reload();
        }
      });
    }
  };

  const [updatingAcceptsTerms, setUpdatingAcceptsTerms] = React.useState(false);
  React.useEffect(() => {
    if (
      showAcceptTerms &&
      window.location.search.indexOf("requestDemo=true") < 0
    ) {
      setUpdatingAcceptsTerms(false);
    }
  }, [showAcceptTerms]);
  React.useEffect(() => {
    if (
      showAcceptTerms &&
      !updatingAcceptsTerms &&
      window.location.search.indexOf("requestDemo=true") < 0
    ) {
      const to = setInterval(() => {
        console.log("updatingAcceptsTerms:", updatingAcceptsTerms);
        checkShow(!updatingAcceptsTerms, "Accept-Terms");
      }, 1000);
      return () => {
        clearInterval(to);
      };
    }
  }, [showAcceptTerms, updatingAcceptsTerms]);

  const udpateAcceptTerms = () => {
    console.log("update profile acceptedTerms flag to true");
    setUpdatingAcceptsTerms(true);
    firestore.collection("users").doc(uid).update({ acceptedTerms: true });
  };

  const AcceptTerms = (
    <>
      <div className="App-Alert-Sticky">
        <div className="Accept-Terms">
          <div className="App-alert">
            <div aria-live="polite" aria-atomic="true">
              Please accept the following Terms and Conditions:
            </div>
            <button className="App-Alert-Button" onClick={udpateAcceptTerms}>
              Ok?
            </button>
          </div>
        </div>
      </div>
      <About docName={"Terms & Conditions"} hideTitle={true} />
    </>
  );

  const AppContent = (
    <Router>
      <Suspense fallback={<Spinner text={"Loading..."} />}>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/spinner" component={Spinner} />
          <Route
            path="/login"
            render={props => (
              <Login
                {...props}
                anonDisplayName={anonDisplayName}
                setAnonDisplayName={setAnonDisplayName}
                noAnon={true}
                withTutorial={withTutorial}
              />
            )}
          />
          <Route exact path="/preferences" component={Preferences} />
          <Route
            exact
            path="/mytracks"
            render={props => (
              <MyTracks {...props} withTutorial={withTutorial} />
            )}
          />
          <Route
            exact
            path="/requests"
            render={props => (
              <Requests
                {...props}
                anonDisplayName={anonDisplayName}
                setAnonDisplayName={setAnonDisplayName}
              />
            )}
          />
          <Route
            exact
            path="/requests/:artistId"
            render={props => (
              <Requests
                {...props}
                anonDisplayName={anonDisplayName}
                setAnonDisplayName={setAnonDisplayName}
              />
            )}
          />
          <Route exact path="/usepositiondemo" component={UsePositionDemo} />
          <Route
            path="/:docName"
            render={props => (
              <About
                {...props}
                setWithTutorial={setWithTutorial}
                withTutorial={withTutorial}
              />
            )}
          />
        </Switch>
        <div>&nbsp;</div>
        <div>&nbsp;</div>
        <div>&nbsp;</div>
        <div>&nbsp;</div>
        <div>&nbsp;</div>
        <div>&nbsp;</div>
        <div>&nbsp;</div>
        <div>&nbsp;</div>
      </Suspense>
    </Router>
  );

  return (
    <>
      {isServiceWorkerInitialized && showSWInit && (
        <div className="App-alert">
          <div
            aria-live="polite"
            aria-atomic="true"
            className="App-Alert-Button"
            onClick={() => setShowSWInit(false)}
          >
            Page has been saved for offline use
          </div>
          <button
            className="App-Alert-Button"
            onClick={() => setShowSWInit(false)}
          >
            Ok
          </button>
        </div>
      )}
      {isServiceWorkerUpdated && showSWUpdated && (
        <div className="App-alert">
          <div aria-live="polite" aria-atomic="true">
            There is a new version available.
          </div>
          <button className="App-Alert-Button" onClick={updateServiceWorker}>
            Update
          </button>
        </div>
      )}
      <div className="App-container">
        <div className="App">
          {window.location.search.indexOf("requestDemo=true") >= 0 && (
            <h2>Request Demo</h2>
          )}
          <Elements stripe={stripePromise}>
            {withTutorial ? (
              <div style={{ display: "flex" }}>
                <div className="App-Split-Container">
                  <div className="App-Split">
                    <About
                      docName={"Tutorial"}
                      hideTitle={true}
                      setWithTutorial={setWithTutorial}
                      withTutorial={withTutorial}
                      setWithSecondApp={setWithSecondApp}
                      withSecondApp={withSecondApp}
                    />
                    <div>&nbsp;</div>
                    <div>&nbsp;</div>
                    <div>&nbsp;</div>
                    <div>&nbsp;</div>
                    <div>&nbsp;</div>
                    <div>&nbsp;</div>
                    <div>&nbsp;</div>
                    <div>&nbsp;</div>
                  </div>
                </div>
                <div className="App-Split-Container">
                  <div className="App-Split">
                    {showAcceptTerms ? (
                      AcceptTerms
                    ) : (
                      <div style={{ marginTop: "10px" }}>{AppContent}</div>
                    )}
                  </div>
                </div>
                <div className="App-iframe-container">
                  <iframe
                    className="App-iframe"
                    src="/?requestDemo=true"
                    title="Requests Demo"
                  ></iframe>
                </div>
              </div>
            ) : (
              <>
                {showAcceptTerms &&
                window.location.search.indexOf("signInFromTutorial=true") < 0
                  ? AcceptTerms
                  : AppContent}
              </>
            )}
          </Elements>
        </div>
      </div>
    </>
  );
};

// export default App;
// export default geolocated({
//   positionOptions: {
//     enableHighAccuracy: false,
//   },
//   userDecisionTimeout: 5000,
// })(App);

const loadArtists = sortedAry => {
  return dispatch => {
    dispatch({ type: "ARY_LOADED", sortedAry });
  };
};

export default connect(s => s, { loadArtists })(App);
