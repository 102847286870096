import { createStore, applyMiddleware, compose, combineReducers } from "redux";
import thunk from "redux-thunk";
import { getFirestore, firestoreReducer } from "redux-firestore";
import { getFirebase, firebaseReducer } from "react-redux-firebase";

export const stripePublicKey =
  "pk_live_51H1PdaF4pzxfhw4inmJfhe1BxnrbFVyBwu0wCjg06y0EBTdZzgH8JU2vTnaAjicEYt1l9jGzW16e73dfhONB7DI300lgIvN1oe";

export const fbConfig = {
  apiKey: "AIzaSyD1DhX7_gCqNqlIvmGc64KQg7vI-EZ5lD0",
  authDomain: "scroll-player.firebaseapp.com",
  databaseURL: "https://scroll-player.firebaseio.com",
  projectId: "scroll-player",
  storageBucket: "scroll-player.appspot.com",
  messagingSenderId: "721794420353",
  appId: "1:721794420353:web:e84495630b17b79ecf4780",
  measurementId: "G-DLESKFN7T8",
};

export const rrfConfig = {
  userProfile: "users",
  useFirestoreForProfile: true, // Store in Firestore instead of Real Time DB
  enableLogging: false,
  enableClaims: true,
  //this doesn't work... in firestore or realtime db...
  // presence: "presence", // where list of online users is stored in database
};

export const SW_INIT = "SW_INIT";
export const SW_UPDATE = "SW_UPDATE";

const swRootReducer = (state = {}, action) => {
  switch (action.type) {
    case SW_INIT:
      return {
        ...state,
        serviceWorkerInitialized: !state.serviceWorkerInitialized,
      };
    case SW_UPDATE:
      return {
        ...state,
        serviceWorkerUpdated: !state.serviceWorkerUpdated,
        serviceWorkerRegistration: action.payload,
      };
    default:
      return state;
  }
};

const artistReducer = (state = {}, action) => {
  switch (action.type) {
    case "ARY_LOADED":
      return {
        ...state,
        sortedAry: action.sortedAry,
      };
    default:
      return state;
  }
};

const rootReducer = combineReducers({
  firebase: firebaseReducer,
  firestore: firestoreReducer,
  swState: swRootReducer,
  artists: artistReducer,
});

export const configureStore = (initialState, history) => {
  const composeEnhancers =
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  const middleware = [thunk.withExtraArgument({ getFirebase, getFirestore })];
  const createStoreWithMiddleware = composeEnhancers(
    applyMiddleware(...middleware)
  )(createStore);
  const store = createStoreWithMiddleware(rootReducer, initialState);

  if (module.hot) {
    // Enable Webpack hot module replacement for reducers
    // module.hot.accept("./reducer", () => {
    //   const nextRootReducer = require("./reducer");
    //   store.replaceReducer(nextRootReducer);
    // });
  }

  return store;
};
