import React from "react";
import { useHistory, Link } from "react-router-dom";
// import { useSelector } from "react-redux";
// import { useFirebase, isEmpty } from "react-redux-firebase";
import { useFirebase } from "react-redux-firebase";
import fbLogo from "./Facebook-Logo.wine.svg";
import gLogo from "./Google-Logo.wine.svg";
import { checkShow } from "./utils";

const checkRedirect = (cancelRedirect, history) => {
  if (!cancelRedirect) {
    history.replace("/");
  }
};

const emailIsValid = email => {
  return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
};

const Login = props => {
  const firebase = useFirebase();
  // const auth = useSelector(state => state.firebase.auth);
  const history = useHistory();
  const cancelRedirect = (props.cancelRedirect && true) || false;

  const { anonDisplayName, setAnonDisplayName, noAnon } = props;

  const signInWithGoogle = () => {
    // I can't seem to figure out how to do this and I don't think it's that necessary right now so...
    // Currently, there will be no ability to roll an anonymous account into an authenticated one that users can sign back into.
    // I don't think I want to allow a trial now if the users can't sign out and sign back in.

    // if (auth.isAnonymous) {
    //   const credential = firebase.auth.GoogleAuthProvider.credential(
    //     // googleUser.getAuthResponse().id_token
    //     auth.stsTokenManager.accessToken
    //   );
    //   firebase
    //     .login({
    //       provider: "google",
    //       type: "popup",
    //     })
    //     .then(
    //       // currentUser
    //       () => {
    //         // currentUser
    //         auth.currentUser  //nope, this don't work either...
    //           .linkWithCredential(credential)
    //           .then(function (usercred) {
    //             var user = usercred.user;
    //             console.log("Anonymous account successfully upgraded", user);
    //           })
    //           .catch(function (error) {
    //             console.log("Error upgrading anonymous account", error);
    //           });
    //         checkRedirect(cancelRedirect, history);
    //       }
    //     );
    // } else {
    firebase
      .login({
        provider: "google",
        type: "popup",
      })
      .then(() => checkRedirect(cancelRedirect, history));
    // }
  };

  const [showEmailInput, setShowEmailInput] = React.useState(false);
  const [email, setEmail] = React.useState(
    window.localStorage.getItem("emailForSignIn") || ""
  );

  const [showCheckEmail, setShowCheckEmail] = React.useState(false);

  React.useEffect(() => {
    checkShow(showCheckEmail, "Check-Email");
  }, [showCheckEmail]);

  const signInWithEmail = () => {
    const url =
      window.location.origin +
      window.location.pathname +
      (props.withTutorial ? "?signInFromTutorial=true" : "");
    // add something like signInFromTutorial if done from tutorial to tell them what to do next
    const actionCodeSettings = {
      url,
      handleCodeInApp: true,
    };
    firebase
      .auth()
      .sendSignInLinkToEmail(email, actionCodeSettings)
      .then(() => {
        window.localStorage.setItem("emailForSignIn", email);
        setShowCheckEmail(true);
        setTimeout(() => {
          setShowEmailInput(false);
          setTimeout(() => {
            history.replace("/");
          }, 5000);
        }, 5000);
      });
  };

  const [
    showSignInCompleteWithTutorial,
    setShowSignInCompleteWithTutorial,
  ] = React.useState(false);

  // Confirm the link is a sign-in with email link.
  React.useEffect(() => {
    if (firebase.auth().isSignInWithEmailLink(window.location.href)) {
      // Additional state parameters can also be passed via URL.
      // This can be used to continue the user's intended action before triggering
      // the sign-in operation.
      // Get the email if available. This should be available if the user completes
      // the flow on the same device where they started it.
      // var semail = window.localStorage.getItem("emailForSignIn");
      if (!email) {
        // User opened the link on a different device. To prevent session fixation
        // attacks, ask the user to provide the associated email again. For example:
        // semail = window.prompt("Please provide your email for confirmation");
        return <div>No Email found...</div>;
      }
      // The client SDK will parse the code from the link for you.
      firebase
        .auth()
        .signInWithEmailLink(email, window.location.href)
        .then(function (result) {
          // Clear email from storage.
          window.localStorage.removeItem("emailForSignIn");
          if (window.location.search.indexOf("signInFromTutorial=true") >= 0) {
            setShowSignInCompleteWithTutorial(true);
          } else {
            checkRedirect(cancelRedirect, history);
          }
          // You can access the new user via result.user
          // Additional user info profile not available via:
          // result.additionalUserInfo.profile == null
          // You can check if the user is new or existing:
          // result.additionalUserInfo.isNewUser
        })
        .catch(function (error) {
          // Some error occurred, you can inspect the code: error.code
          // Common errors could be invalid email and invalid or expired OTPs.
        });
    }
  }, [cancelRedirect, email, firebase, history]);

  React.useEffect(() => {
    checkShow(showEmailInput, "Email-SignIn");
  }, [showEmailInput]);

  // not even sure I'll ever need this really...
  // if (!isLoaded(auth)) return <Spinner />;
  //user already logged in?
  // if (!isEmpty(auth)) history.replace("/");

  const signInWithFacebook = () => {
    var provider = new firebase.auth.FacebookAuthProvider();
    firebase
      .auth()
      .signInWithPopup(provider)
      .then(function (result) {
        // This gives you a Facebook Access Token. You can use it to access the Facebook API.
        var token = result.credential.accessToken;
        // The signed-in user info.
        var user = result.user;
        // ...
        console.log(token, user);
        checkRedirect(cancelRedirect, history);
      })
      .catch(function (error) {
        // Handle Errors here.
        var errorCode = error.code;
        var errorMessage = error.message;
        // The email of the user's account used.
        var email = error.email;
        // The firebase.auth.AuthCredential type that was used.
        var credential = error.credential;
        // ...
        console.log(errorCode, errorMessage, email, credential);
      });
  };

  const handleAnonLogin = () => {
    if (window.location.search.indexOf("requestDemo=true") >= 0) {
      //this might be the right way to do search parameters into a url...
      const params = new URLSearchParams(window.location.search);
      params.set("anonDisplayName", anonDisplayName);
      history.replace(`${window.location.pathname}?${params}`);
    } else {
      firebase
        .auth()
        .signInAnonymously()
        .then(() => {
          //yeah, you do get an object here with (object) => object.user.uid but I think we can do displayName update with the geohash update function
          checkRedirect(cancelRedirect, history);
        })
        .catch(function (error) {
          // Handle Errors here.
          var errorCode = error.code;
          var errorMessage = error.message;
          console.log(errorCode);
          console.log(errorMessage);
          // ...
        });
    }
  };

  return (
    <div className="Login-Page">
      {showSignInCompleteWithTutorial ? (
        <>
          <h2>Sign In Complete</h2>
          <p>
            You may close this window and return to the app with the tutorial
          </p>
        </>
      ) : (
        <>
          {!noAnon ? <h2>Introduce yourself!</h2> : <h2>Sign In</h2>}
          <div>
            <button
              className="App-navbutton"
              onClick={event => {
                event.preventDefault();
                signInWithGoogle();
              }}
              disabled={window.location.search.indexOf("requestDemo=true") >= 0}
            >
              Sign In with <img src={gLogo} alt="Google" />
            </button>
          </div>
          <div>
            <button
              className="App-navbutton"
              onClick={event => {
                event.preventDefault();
                signInWithFacebook();
              }}
              disabled={window.location.search.indexOf("requestDemo=true") >= 0}
            >
              Sign In with <img src={fbLogo} alt="Facebook" />
            </button>
          </div>
          {true && (
            <>
              <div className="Email-SignIn">
                <div className="Text-Container">
                  <p className="Text-Content">
                    This method requires you to be able to click on a link sent
                    to the email address you provide below.
                  </p>
                </div>
                <div>
                  <input
                    type="email"
                    value={email}
                    onChange={e => setEmail(e.target.value)}
                    name="email"
                    placeholder="Enter Email Address Here"
                  />
                </div>
                <div>
                  <button
                    className="App-navbutton"
                    disabled={!emailIsValid(email)}
                    onClick={event => {
                      event.preventDefault();
                      signInWithEmail();
                    }}
                  >
                    Send Sign In Link
                  </button>
                </div>
                {/* {showCheckEmail && <div>Sign In Link sent. Check your email.</div>} */}
                <div className="Check-Email">
                  Sign In Link sent. Check your email.
                </div>
              </div>
            </>
          )}
          <div>
            <button
              className="App-navbutton"
              onClick={event => {
                event.preventDefault();
                setShowEmailInput(s => !s);
              }}
              disabled={window.location.search.indexOf("requestDemo=true") >= 0}
            >
              Sign In with Email
            </button>
          </div>
          {!noAnon && (
            <>
              <div>&nbsp;</div>
              <div>
                <div>...or just tell us your name</div>
                <div>
                  <input
                    type="text"
                    name="dispalyName"
                    value={anonDisplayName}
                    placeholder="Your Name"
                    onChange={e => {
                      setAnonDisplayName(e.target.value);
                    }}
                  />
                </div>
                <div>
                  <button
                    className="App-navbutton"
                    disabled={anonDisplayName === ""}
                    onClick={handleAnonLogin}
                  >
                    Sign In Anonymously
                  </button>
                </div>
              </div>
            </>
          )}
        </>
      )}
      <div>&nbsp;</div>
      <div>
        <button
          className="App-navbutton"
          onClick={() => history.push("/" + window.location.search)}
        >
          Home
        </button>
      </div>
      <div>
        <Link
          className="App-link"
          to={"Privacy%20Policy" + window.location.search}
        >
          Privacy Policy
        </Link>
      </div>
      <div>
        <Link
          className="App-link"
          to={"Terms%20&%20Conditions" + window.location.search}
        >
          Terms Of Use
        </Link>
      </div>
    </div>
  );
};

export default Login;
