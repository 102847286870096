import { useState, useEffect } from "react";
import geohash from "ngeohash";

export const usePosition = ({ userAcceptedTerms }) => {
  const [position, setPosition] = useState({});
  const [error, setError] = useState(null);
  // const [userAllowGeo, setUserAllowGeo] = useState(true);

  const onChange = ({ coords }) => {
    setPosition({
      lat: coords.latitude,
      lng: coords.longitude,
      hash: geohash.encode(coords.latitude, coords.longitude),
      coords: coords,
    });
  };

  const onError = error => {
    setError(error.message);
    // setUserAllowGeo(false);
  };

  useEffect(() => {
    const geo = navigator.geolocation;
    if (!geo) {
      setError("Geolocation is not supported");
      return;
    }
    let watcher;
    // console.log(userAllowGeo);
    // if (userAllowGeo) {
    if (userAcceptedTerms) {
      //some kind of permission flag
      watcher = geo.watchPosition(onChange, onError);
    } else {
      watcher = null;
    }

    return () => geo.clearWatch(watcher);
  }, [userAcceptedTerms]);

  // return { ...position, err: error, setUserAllowGeo, allowGeo: userAllowGeo };
  return { ...position, err: error };
};
