import React from "react";
import { useSelector } from "react-redux";
import { useHistory, Link } from "react-router-dom";
import { useFirebase, useFirestore } from "react-redux-firebase";
// import { useFirebase, useFirestore } from "react-redux-firebase";
import { transpositionOptions, transpositionOptionSemitones } from "./utils";
// import PaymentOptions from "./PaymentOptions";
// import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { useStripe } from "@stripe/react-stripe-js";
import gLogo from "./Google-Logo.wine.svg";
import fbLogo from "./Facebook-Logo.wine.svg";
import { checkShow } from "./utils";

// import * as stripeFunctions from "./stripe";

// const CARD_ELEMENT_OPTIONS = {
//   iconStyle: "solid",
//   style: {
//     base: {
//       iconColor: "#aaa",
//       color: "#eee", // this is too bright... I think.
//       backgroundColor: "#333",
//       fontSize: "16px",
//       "::placeholder": {
//         color: "#888",
//       },
//     },
//     invalid: {
//       color: "#e5424d",
//       ":focus": {
//         color: "#303238",
//       },
//     },
//   },
// };

let params = new URLSearchParams(window.location.search);
let stripeConnectCode = params.get("code") || "";
let stripeConnectState = params.get("state") || "";
let stripeConnectError = params.get("error") || "";
let stripeConnectErrorDescription = params.get("error_description") || "";

const checkStripeConnect = async call_connect_oauth => {
  const scs = stripeConnectState;
  stripeConnectState = "";
  if (stripeConnectError !== "" || stripeConnectErrorDescription !== "") {
    console.log(stripeConnectError, stripeConnectErrorDescription);
  } else {
    const response = await call_connect_oauth({
      state: scs,
      code: stripeConnectCode,
    });
    if (response.data.error) {
      console.log(response.data.error);
      stripeConnectError = response.data.error.code;
      stripeConnectErrorDescription = response.data.error.description;
    } else {
      window.location = window.location.origin + window.location.pathname;
    }
  }
};

const updateRequestDocs = async ({
  currentRequests,
  firestore,
  previousUser,
  currentUser,
}) => {
  const artistIds = Object.keys(currentRequests || {});
  //get the current user's displayname
  const userSS = await firestore.collection("users").doc(currentUser.uid).get();
  const userData = userSS.data();
  const displayName = userData.displayName || currentUser.name;
  for (const artistId of artistIds) {
    const docRef = await firestore.collection("requests").doc(artistId).get();
    let docData = await docRef.data();
    for (const trackId of currentRequests[artistId]) {
      let updateMade = false;
      let reqAry = docData[trackId] || [];
      console.log("reqAry:", reqAry);
      for (const req of reqAry) {
        if (req.uid === previousUser.uid) {
          req.uid = currentUser.uid;
          req.displayName = displayName;
          updateMade = true;
        }
      }
      if (updateMade) {
        console.log("reqAry:", reqAry);
        await firestore
          .collection("requests")
          .doc(artistId)
          .update({
            [trackId]: reqAry,
          });
      }
    }
  }
};

const Preferences = props => {
  const firebase = useFirebase();
  const firestore = useFirestore();
  const auth = useSelector(state => state.firebase.auth);
  const profile = useSelector(state => state.firebase.profile);
  const history = useHistory();

  // const callCreateSub =
  //   firebase &&
  //   firebase.functions &&
  //   firebase.functions().httpsCallable("createsubscription");
  // const callRetrySub =
  //   firebase &&
  //   firebase.functions &&
  //   firebase.functions().httpsCallable("retrysubscription");
  // const callCancelSub =
  //   firebase &&
  //   firebase.functions &&
  //   firebase.functions().httpsCallable("cancelsubscription");
  // global.callCreateSub = callCreateSub;
  // global.callRetrySub = callRetrySub;
  // global.callCancelSub = callCancelSub;

  const callFetchSession =
    firebase &&
    firebase.functions &&
    firebase.functions().httpsCallable("fetchSession");
  const call_create_customer_portal_session =
    firebase &&
    firebase.functions &&
    firebase.functions().httpsCallable("create_customer_portal_session");
  const call_get_oauth_link =
    firebase &&
    firebase.functions &&
    firebase.functions().httpsCallable("get_oauth_link");
  const call_connect_oauth =
    firebase &&
    firebase.functions &&
    firebase.functions().httpsCallable("connect_oauth");

  if (stripeConnectState !== "") {
    checkStripeConnect(call_connect_oauth);
  }

  const [transposeOptions, setTransposeOptions] = React.useState();
  const [savedTransposeOptions, setSavedTransposeOptions] = React.useState();
  const [dName, setDName] = React.useState("");
  const [savedDName, setSavedDName] = React.useState("");
  const [artistDescription, setArtistDescription] = React.useState("");
  const [savedArtistDescription, setSavedArtistDescription] = React.useState(
    ""
  );
  const [generalTipMessage, setGeneralTipMessage] = React.useState("");
  const [savedGeneralTipMessage, setSavedGeneralTipMessage] = React.useState(
    ""
  );

  const linkRef = React.createRef();

  React.useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    if (profile) {
      setDName(params.get("anonDisplayName") || profile.displayName);
      setSavedDName(params.get("anonDisplayName") || profile.displayName);
      setArtistDescription(profile.artistDescription);
      setSavedArtistDescription(profile.artistDescription);
      setGeneralTipMessage(profile.generalTipMessage);
      setSavedGeneralTipMessage(profile.generalTipMessage);
    }
  }, [profile]);

  React.useEffect(() => {
    if (profile && profile.transposeOptions) {
      let pto = profile.transposeOptions;

      let nto = pto
        .map(o => [o.direction, o.step, o.semitones].join(","))
        .join("|");

      setTransposeOptions(nto);
      setSavedTransposeOptions(nto);
    }
  }, [profile]);

  const handleDirectionChange = (e, o, i) => {
    const newDirection = e.target.value;
    const currentStepValue = o.split(",")[1];
    const newSemitones =
      transpositionOptionSemitones[currentStepValue.toString()] *
      (newDirection === "up" ? 1 : -1);
    const newOption =
      newDirection + "," + currentStepValue + "," + newSemitones;

    let transposeOptionsArray = transposeOptions.split("|");
    transposeOptionsArray.splice(i, 1, newOption);
    const newTransposeOptionsArray = transposeOptionsArray.join("|");

    setTransposeOptions(newTransposeOptionsArray);
  };

  const handleStepChange = (e, o, i) => {
    const currentDirection = o.split(",")[0];
    const newStepValue = e.target.value;
    const newSemitones =
      transpositionOptionSemitones[newStepValue.toString()] *
      (currentDirection === "up" ? 1 : -1);
    const newOption =
      currentDirection + "," + newStepValue + "," + newSemitones;

    let transposeOptionsArray = transposeOptions.split("|");
    transposeOptionsArray.splice(i, 1, newOption);
    const newTransposeOptionsArray = transposeOptionsArray.join("|");

    setTransposeOptions(newTransposeOptionsArray);
  };

  const handleSaveTransposeOptions = () => {
    setSavedTransposeOptions(transposeOptions);

    let pto = transposeOptions.split("|");

    let nto = pto
      .filter(s => s.indexOf(",") >= 0)
      .map(s => ({
        direction: s.split(",")[0],
        step: s.split(",")[1],
        semitones: Number(s.split(",")[2]),
      }));

    firestore
      .collection("users")
      .doc(auth.uid)
      .update({ transposeOptions: nto });
  };

  const handleSaveDName = () => {
    setSavedDName(dName);
    if (window.location.search.indexOf("requestDemo=true") < 0) {
      firestore
        .collection("users")
        .doc(auth.uid)
        .update({ displayName: dName });
      if (isArtist) {
        // updateAllArtistsDoc();
      }
    }
    const currentUser = { ...profile, displayName: dName, uid: profile.userId };
    console.log(currentUser);
    updateRequestDocs({
      currentRequests: profile.requests,
      firestore,
      previousUser: currentUser,
      currentUser,
    });
  };

  const handleSaveArtistDescription = () => {
    setSavedArtistDescription(artistDescription);
    firestore.collection("users").doc(auth.uid).update({ artistDescription });
    // updateAllArtistsDoc();
  };

  const handleSaveGeneralTipMessage = () => {
    setSavedGeneralTipMessage(generalTipMessage);
    firestore.collection("users").doc(auth.uid).update({ generalTipMessage });
  };

  const isArtist =
    (profile &&
      profile.token &&
      profile.token.claims &&
      profile.token.claims.artist &&
      true) ||
    false;

  const isTrial = (profile && profile.isTrial && true) || false;

  // const [showSignUp, setShowSignUp] = React.useState();
  const [err, setErr] = React.useState();
  const [working, setWorking] = React.useState(false);

  const logout = () => {
    if (window.location.search.indexOf("requestDemo=true") < 0) {
      firebase.auth().signOut();
      history.replace("/");
    } else {
      const params = new URLSearchParams(window.location.search);
      params.delete("anonDisplayName");
      history.replace(`/?${params}`);
    }
  };

  // logout();

  const handleStartTrial = () => {
    console.log("start trial");
    setWorking(true);
    firestore
      .collection("subscriptionRequests")
      .add({
        role: "artist",
        token: false,
        uid: auth.uid,
      })
      .catch(err => {
        console.error(err);
      });
  };

  const stripe = useStripe();
  // const elements = useElements();
  const userPrivate = useSelector(
    state => state.firestore.data.userPrivate || {}
  );

  // const stripeFunctionAssets = {
  //   callCreateSub,
  //   callRetrySub,
  //   callCancelSub,
  //   customerId: userPrivate && userPrivate.info && userPrivate.info.customer_id,
  //   subscriptionId:
  //     userPrivate && userPrivate.info && userPrivate.info.subscription_id,
  //   stripe,
  //   setErr,
  //   setWorking,
  // };

  // const handleSubscribe = async e => {
  //   e.preventDefault();

  //   if (!stripe || !elements) {
  //     return;
  //   }

  //   setWorking(true);

  //   const cardElement = elements.getElement(CardElement);
  //   const priceId = "prod_HkAED4Vo2Gi7ij";

  //   const sfAssets = {
  //     ...stripeFunctionAssets,
  //     cardElement,
  //     priceId,
  //   };

  //   await stripeFunctions.createPaymentMethod(sfAssets);
  // };

  // const handleCancelSubscription = async () => {
  //   if (!stripe || !elements) {
  //     return;
  //   }

  //   setWorking(true);

  //   const sfAssets = {
  //     ...stripeFunctionAssets,
  //     // cardElement,
  //     // priceId,
  //   };

  //   await stripeFunctions.cancelSubscription(sfAssets);
  // };

  React.useEffect(() => {
    if (profile && profile.artist !== undefined) {
      if (isArtist !== profile.artist) {
        window.location.reload();
      }
    }
  }, [isArtist, profile]);

  React.useEffect(() => {
    // setShowSignUp(false);
    setWorking(false);
    // setErr(); //not sure here....
  }, [profile]);

  const handleCopyLink = () => {
    let textElement = linkRef.current;
    textElement.select();
    textElement.setSelectionRange(0, 99999); /*For mobile devices*/
    document.execCommand("copy");
    alert(
      `The following link has been copied to your clipboard\n${window.location.protocol}//${window.location.host}/requests/${auth.uid}`
    );
    // alert("Link Copied");
  };

  const handleCheckout = async e => {
    // Call your backend to create the Checkout session.
    // const { sessionId } = await callFetchSession();
    //window.location.origin + window.location.pathname
    setWorking(true);
    const response = await callFetchSession({
      successUrl: window.location.origin + window.location.pathname,
      customerId:
        userPrivate && userPrivate.info && userPrivate.info.customer_id,
      email: userPrivate && userPrivate.info && userPrivate.info.email,
    });
    console.log(response);
    const sessionId = response.data.session.id;
    // return;

    // When the customer clicks on the button, redirect them to Checkout.
    // const stripe = await stripePromise;
    const { error } = await stripe.redirectToCheckout({
      sessionId,
    });

    // If `redirectToCheckout` fails due to a browser or network
    // error, display the localized error message to your customer
    // using `error.message`.
    if (error) {
      setErr(error.message);
    } else {
    }
  };

  const handleManageSubscription = async () => {
    setWorking(true);
    const response = await call_create_customer_portal_session({
      return_url: window.location.origin + window.location.pathname,
      customerId:
        userPrivate && userPrivate.info && userPrivate.info.customer_id,
      // email: userPrivate && userPrivate.info && userPrivate.info.email,
    });
    const sessionUrl = response.data.session.url;

    // props.history.push(sessionUrl);
    window.location = sessionUrl;
  };

  const handleConnectWithStripe = async () => {
    setWorking(true);
    const response = await call_get_oauth_link({
      url: window.location.origin + window.location.pathname,
    });
    if (response.data.url) {
      window.location = response.data.url;
    } else {
      console.error(response.data.error);
      setWorking(false);
    }
  };

  const linkAccount = ({ provider }) => {
    let googleProvider;
    switch (provider) {
      case "google":
        googleProvider = new firebase.auth.GoogleAuthProvider();
        break;
      case "facebook":
        googleProvider = new firebase.auth.FacebookAuthProvider();
        break;
      default:
        return;
    }
    const currentRequests = profile.requests;
    console.log("currentRequests:", currentRequests);
    firebase
      .auth()
      .currentUser.linkWithPopup(googleProvider)
      .then(async result => {
        console.log(result);
        const newDisplayName = result.additionalUserInfo.profile.name;
        const newEmail = result.additionalUserInfo.profile.email;

        // now update user profile
        await firestore
          .doc(`/users/${auth.uid}`)
          .set(
            { displayName: newDisplayName, isAnon: false, requests: {} },
            { merge: true }
          );
        await firestore
          .doc(`/users/${auth.uid}/private/info`)
          .set({ email: newEmail }, { merge: true });
        //do we need to update the displayName in each request?
        const currentUser = {
          ...result.additionalUserInfo.profile,
          displayName: newDisplayName,
          uid: auth.uid,
        };
        console.log(currentUser);
        updateRequestDocs({
          currentRequests,
          firestore,
          previousUser: currentUser,
          currentUser,
        });
      })
      .catch(error => {
        // Handle Errors here.
        // ...
        const credential = error.credential;
        console.log(error);
        if (
          error.code === "auth/credential-already-in-use" ||
          error.code === "auth/email-already-in-use" ||
          error.code === "auth/account-exists-with-different-credential"
        ) {
          console.log("email already in use");
          const previousUser = firebase.auth().currentUser;
          firebase
            .auth()
            .signInWithCredential(credential)
            .then(result => {
              console.log(result);
              //we should then update any requests made by the previous anon user
              //with the current user id and displayName (or name) now
              const currentUser = {
                ...result.additionalUserInfo.profile,
                uid: result.user.uid,
              };

              if (currentRequests) {
                updateRequestDocs({
                  currentRequests,
                  firestore,
                  previousUser,
                  currentUser,
                });
              }
            })
            .catch(error => {
              console.error(error);
            });
        }
      });
  };

  const [email, setEmail] = React.useState(
    window.localStorage.getItem("emailForSignIn") || ""
  );

  const emailIsValid = email => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  };

  const [showEmailInput, setShowEmailInput] = React.useState(false);

  React.useEffect(() => {
    checkShow(showEmailInput, "Email-SignIn");
  }, [showEmailInput]);

  const [showCheckEmail, setShowCheckEmail] = React.useState(false);

  React.useEffect(() => {
    checkShow(showCheckEmail, "Check-Email");
  }, [showCheckEmail]);

  const signInWithEmail = () => {
    const url =
      window.location.origin +
      window.location.pathname +
      (props.withTutorial ? "?signInFromTutorial=true" : "");
    // add something like signInFromTutorial if done from tutorial to tell them what to do next
    const actionCodeSettings = {
      url,
      handleCodeInApp: true,
    };
    // setShowCheckEmail(true);
    true &&
      firebase
        .auth()
        .sendSignInLinkToEmail(email, actionCodeSettings)
        .then(() => {
          window.localStorage.setItem("emailForSignIn", email);
          window.localStorage.setItem("previousUid", profile.userId);
          if (userPrivate && userPrivate.info && userPrivate.info.customer_id) {
            window.localStorage.setItem(
              "previousStripeCid",
              userPrivate.info.customer_id
            );
          }
          setShowCheckEmail(true);
          setTimeout(() => {
            setShowEmailInput(false);
            // setTimeout(() => {
            //   history.replace("/");
            // }, 5000);
          }, 5000);
        });
  };

  React.useEffect(() => {
    if (firebase.auth().isSignInWithEmailLink(window.location.href)) {
      // Additional state parameters can also be passed via URL.
      // This can be used to continue the user's intended action before triggering
      // the sign-in operation.
      // Get the email if available. This should be available if the user completes
      // the flow on the same device where they started it.
      // var semail = window.localStorage.getItem("emailForSignIn");
      if (!email) {
        // User opened the link on a different device. To prevent session fixation
        // attacks, ask the user to provide the associated email again. For example:
        // semail = window.prompt("Please provide your email for confirmation");
        return <div>No Email found...</div>;
      }
      // The client SDK will parse the code from the link for you.
      firebase
        .auth()
        .signInWithEmailLink(email, window.location.href)
        .then(async result => {
          console.log("result:", result);

          // let's try and get the previous user id
          const previousUid = window.localStorage.getItem("previousUid") || "";
          console.log("previousUid:", previousUid);

          // let's try and get the previous user profile now
          const userRef = await firestore.doc(`/users/${previousUid}`).get();
          let previousUser = { ...userRef.data() };
          console.log("previousUser:", previousUser);

          // what about the private info - specifically the stripe customer id?
          // see if they tip with auth account if you get the same customer id in new private profile.
          const stripeCid =
            window.localStorage.getItem("previousStripeCid") || "";

          // try to do what you do with linking...

          // here you wouldn't have a name - since this just came from an email link.
          // see if we can keep the anon name...
          const newDisplayName = previousUser.displayName;
          // you also don't have a profile...
          const newEmail = result.user.email;

          // now update user profile
          await firestore.doc(`/users/${result.user.uid}`).set(
            {
              ...previousUser,
              displayName: newDisplayName,
              isAnon: false,
              requests: {},
              userId: result.user.uid,
              email: "",
            },
            { merge: true }
          );

          let newPrivateInfo = {
            email: newEmail,
            uid: result.user.uid,
          };
          if (stripeCid !== "") {
            newPrivateInfo.customer_id = stripeCid;
          }
          await firestore
            .doc(`/users/${result.user.uid}/private/info`)
            .set(newPrivateInfo, { merge: true });

          //now we need to update the displayName in each request
          const currentUser = {
            displayName: newDisplayName,
            uid: result.user.uid,
            name: newDisplayName,
          };
          console.log(currentUser);

          previousUser.uid = previousUid;

          updateRequestDocs({
            currentRequests: previousUser.requests,
            firestore,
            previousUser,
            currentUser,
          });

          // Clear email from storage.
          window.localStorage.removeItem("emailForSignIn");
          window.localStorage.removeItem("previousUid");
          window.localStorage.removeItem("previousStripeCid");
          if (window.location.search.indexOf("signInFromTutorial=true") >= 0) {
            // setShowSignInCompleteWithTutorial(true);
          } else {
            // checkRedirect(cancelRedirect, history);
          }
          // You can access the new user via result.user
          // Additional user info profile not available via:
          // result.additionalUserInfo.profile == null
          // You can check if the user is new or existing:
          // result.additionalUserInfo.isNewUser
        })
        .catch(function (error) {
          console.log("error:", error);
          // Some error occurred, you can inspect the code: error.code
          // Common errors could be invalid email and invalid or expired OTPs.
        });
    }
    // }, [cancelRedirect, email, firebase, history]);
  }, [email, firebase, firestore]);

  return (
    <div className="Preferences-Container">
      <div className="Preferences-Content">
        {!props.fromRequests && <h2>Preferences</h2>}
        {props.fromRequests && <div>&nbsp;</div>}
        <div>
          <div className="Preferences-Section">
            <div>Display Name:</div>
            <div>
              <input
                type="text"
                name="dName"
                value={dName || ""}
                onChange={e => setDName(e.target.value)}
              />
            </div>
            <div>
              <button
                className="App-div-button"
                onClick={handleSaveDName}
                disabled={dName === savedDName}
              >
                Save
              </button>
            </div>
          </div>
          {/* <div className="Preferences-Section">
          <PaymentOptions />
        </div> */}
        </div>
        {/* {profile.isAnon && ( */}
        {true && (
          <div>
            <div>
              <button
                className="App-navbutton login-button"
                onClick={event => {
                  event.preventDefault();
                  linkAccount({ provider: "google" });
                }}
                disabled={
                  window.location.search.indexOf("requestDemo=true") >= 0
                }
              >
                Link with <img src={gLogo} alt="Google" />
              </button>
            </div>
            {true && (
              <div>
                <button
                  className="App-navbutton login-button"
                  onClick={event => {
                    event.preventDefault();
                    linkAccount({ provider: "facebook" });
                  }}
                  disabled={
                    window.location.search.indexOf("requestDemo=true") >= 0
                  }
                >
                  Link with <img src={fbLogo} alt="Facebook" />
                </button>
              </div>
            )}
            {true && (
              <>
                <div className="Email-SignIn">
                  <div className="Text-Container">
                    <p className="Text-Content">
                      This method requires you to be able to click on a link
                      sent to the email address you provide below.
                    </p>
                  </div>
                  <div>
                    <input
                      type="email"
                      value={email}
                      onChange={e => setEmail(e.target.value)}
                      name="email"
                      placeholder="Enter Email Address Here"
                    />
                  </div>
                  <div>
                    <button
                      className="App-navbutton"
                      disabled={!emailIsValid(email)}
                      onClick={event => {
                        event.preventDefault();
                        signInWithEmail();
                      }}
                    >
                      Send Sign In Link
                    </button>
                  </div>
                  {/* {showCheckEmail && <div>Sign In Link sent. Check your email.</div>} */}
                  <div className="Check-Email">
                    Sign In Link sent. Check your email.
                  </div>
                </div>
              </>
            )}
            <div>
              <button
                className="App-navbutton"
                onClick={event => {
                  event.preventDefault();
                  setShowEmailInput(s => !s);
                }}
                disabled={
                  window.location.search.indexOf("requestDemo=true") >= 0
                }
              >
                Link with Email
              </button>
            </div>
          </div>
        )}
        {true &&
          !props.fromRequests &&
          isArtist &&
          window.location.search.indexOf("requestDemo=true") < 0 && (
            <div>
              <h3>Musician Options</h3>
              <div className="Preferences-Section">
                <div>Description:</div>
                <div>
                  <input
                    type="text"
                    name="newLabel"
                    value={artistDescription || ""}
                    onChange={e => setArtistDescription(e.target.value)}
                  />
                </div>
                <div>
                  {artistDescription !== savedArtistDescription ? (
                    <button
                      className="App-div-button"
                      onClick={handleSaveArtistDescription}
                    >
                      Save
                    </button>
                  ) : (
                    <button className="App-div-button-disabled">Save</button>
                  )}
                </div>
              </div>
              <div className="Preferences-Section">
                <div>Direct Request Link</div>
                <div>
                  <input
                    type="text"
                    value={`${window.location.protocol}//${window.location.host}/requests/${auth.uid}`}
                    ref={linkRef}
                    readOnly
                  />
                </div>
                <div>
                  <button className="App-div-button" onClick={handleCopyLink}>
                    Copy
                  </button>
                </div>
              </div>
              <div className="Preferences-Section">
                <div>Transpose Options:</div>
                <div>
                  {transposeOptions &&
                    transposeOptions.split("|").map((v, i) => {
                      const o = {
                        value: v,
                        direction: v.split(",")[0],
                        step: v.split(",")[1],
                        semitones: v.split(",")[2],
                      };
                      return (
                        <div key={i + v} className="control-container">
                          <div>
                            <div>
                              <label>Direction:</label>
                            </div>
                            <div>
                              <select
                                className="dropdown-input"
                                name={"direction-for-" + v}
                                value={o.direction || ""}
                                onChange={e => handleDirectionChange(e, v, i)}
                              >
                                <option value=""></option>
                                <option value="up">Up</option>
                                <option value="down">Down</option>
                              </select>
                            </div>
                          </div>
                          <div>
                            <div>
                              <label>Step:</label>
                            </div>
                            <div>
                              <select
                                className="dropdown-input"
                                name={"step-for-" + v}
                                value={o.step || ""}
                                onChange={e => handleStepChange(e, v, i)}
                              >
                                {Object.entries(transpositionOptions).map(v => (
                                  <option key={v[1]} value={v[1]}>
                                    {v[0]}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
                          <div>
                            <button
                              className="App-div-button"
                              onClick={() => {
                                let tosAry = transposeOptions.split("|");
                                tosAry.splice(i, 1);
                                let newTos = tosAry.join("|");
                                setTransposeOptions(newTos);
                              }}
                            >
                              Clear
                            </button>
                          </div>
                        </div>
                      );
                    })}
                </div>
                <div>
                  <button
                    className="App-div-button"
                    onClick={() => {
                      let newTos =
                        (transposeOptions || "") === ""
                          ? ",0,0"
                          : transposeOptions + "|,0,0";
                      setTransposeOptions(newTos);
                    }}
                    disabled={
                      (transposeOptions || "") !== "" &&
                      (transposeOptions === ",0,0" ||
                        transposeOptions.indexOf("|,0,0") >= 0)
                    }
                  >
                    Add Transpose Option
                  </button>
                  {false && (
                    <>
                      <div>
                        transposeOptions:{" "}
                        {
                          (
                            (transposeOptions && transposeOptions.split("|")) ||
                            []
                          ).length
                        }
                      </div>
                      <div>
                        check length:{" "}
                        {
                          (
                            (transposeOptions && transposeOptions.split("|")) ||
                            []
                          ).filter((v, i) => v.direction === "" && v.step === 0)
                            .length
                        }
                      </div>
                      <div>transposeOptions: {transposeOptions}</div>
                      <div>savedTransposeOptions: {savedTransposeOptions}</div>
                    </>
                  )}
                </div>
                <div>&nbsp;</div>
                <div>
                  <button
                    className="App-div-button"
                    // className="App-navbutton"
                    onClick={handleSaveTransposeOptions}
                    disabled={
                      (transposeOptions || "") === "" ||
                      (transposeOptions || "") === ",0,0" ||
                      transposeOptions.replace("|,0,0", "") ===
                        savedTransposeOptions
                    }
                  >
                    Save Transpose Options
                  </button>
                </div>
              </div>
            </div>
          )}
        {/* <div>&nbsp;</div> */}
        {!props.fromRequests && (
          <div>
            {(auth.isAnonymous ||
              window.location.search.indexOf("requestDemo=true") >= 0) && (
              <div>
                You currently have an anonymous account. If you logout, your
                profile will be removed.
              </div>
            )}
            <button className="App-navbutton" onClick={logout}>
              Logout
            </button>
            {(auth.isAnonymous ||
              window.location.search.indexOf("requestDemo=true") >= 0) && (
              <>
                <div>
                  If you'd like tip the artist through the app or you want to do
                  a trial of the service, log out and then log in with one of
                  the other sign in options.
                </div>
              </>
            )}
          </div>
        )}
        {/* <div>&nbsp;</div> */}
        {!(
          auth.isAnonymous ||
          window.location.search.indexOf("requestDemo=true") >= 0
        ) &&
          !props.fromRequests && (
            <>
              <h3>Become A Musician</h3>
              <div className="Preferences-Section">
                {(!isArtist || isTrial) && (
                  <>
                    {isTrial && isArtist ? (
                      <p>
                        Thanks for giving it a shot. Feel free to cancel this
                        trial at any time and I'll get rid of all your stuff for
                        you.
                      </p>
                    ) : (
                      <p>
                        Depending on availability, you can start a trial where
                        you can upload a few tracks and lead sheets to see how
                        things work. All your stuff is removed when you leave.
                      </p>
                    )}
                    <div>
                      {!working ? (
                        <button
                          onClick={handleStartTrial}
                          className="App-navbutton"
                        >
                          {isTrial && isArtist ? "Cancel" : "Start"} Trial
                        </button>
                      ) : (
                        <button disabled className="App-navbutton">
                          {isTrial && isArtist ? "Cancel" : "Start"} Trial
                        </button>
                      )}
                    </div>
                  </>
                )}
                {(!isArtist || isTrial) && (
                  <>
                    <p>
                      If you think the app is worth something and would like to
                      help contribute to its development, subscribe and for $40
                      a month I think I can afford to keep your stuff for you.
                      Please note that trial content cannot be transferred and
                      canceled subscriptions will terminate at the end of your
                      billing cycle.
                    </p>
                    {err && <div>{err}</div>}
                    <button
                      className="App-navbutton"
                      onClick={handleCheckout}
                      disabled={working}
                    >
                      Subscribe
                    </button>
                  </>
                )}

                {isArtist && !isTrial && (
                  <>
                    <div>Tips:</div>
                    {userPrivate &&
                    userPrivate.info &&
                    userPrivate.info.stripe_account_id &&
                    userPrivate.info.stripe_account_id !== "" ? (
                      <>
                        <div>
                          General Tip Link: This is the text that will appear in
                          the general tip link presented to listners.
                        </div>
                        <div>
                          <input
                            type="text"
                            name="newLabel"
                            value={generalTipMessage || ""}
                            onChange={e => setGeneralTipMessage(e.target.value)}
                          />
                        </div>
                        <div>
                          <button
                            className="App-div-button"
                            onClick={handleSaveGeneralTipMessage}
                            disabled={
                              generalTipMessage === savedGeneralTipMessage
                            }
                          >
                            Save
                          </button>
                        </div>
                      </>
                    ) : (
                      <>
                        <p>
                          If you would like listeners to leave you a tip through
                          the app, enter your payout information below. The app
                          needs 20% plus $0.20 per transaction though to offset
                          payment processing, fees, and addition useage costs
                          incurred.
                        </p>
                        <button
                          className="App-navbutton"
                          onClick={handleConnectWithStripe}
                          disabled={working}
                        >
                          Enter Payout Information
                        </button>
                        {stripeConnectError !== "" && (
                          <div>{stripeConnectError}</div>
                        )}
                        {stripeConnectErrorDescription !== "" && (
                          <div>{stripeConnectErrorDescription}</div>
                        )}
                      </>
                    )}
                    <p>
                      Thank you for your contribution. Feel free to cancel your
                      subscription at anytime. All of your files and lists will
                      be removed at the end of your billing cycle.
                    </p>
                    <button
                      className="App-navbutton"
                      onClick={handleManageSubscription}
                      disabled={working}
                    >
                      Manage Billing
                    </button>
                  </>
                )}
              </div>
            </>
          )}
        <div>
          <Link className="App-link" to="Terms%20&%20Conditions">
            Terms Of Use
          </Link>
          &nbsp;
          <Link className="App-link" to="Privacy%20Policy">
            Privacy Policy
          </Link>
        </div>
        &nbsp;
        <div>
          <button
            className="App-navbutton"
            onClick={() => {
              if (props.fromRequests) {
                props.setRequestProfile(false);
              } else {
                history.push("/" + window.location.search);
              }
            }}
          >
            {props.fromRequests ? "Later" : "Home"}
          </button>
        </div>
        <div>&nbsp;</div>
      </div>
    </div>
  );
};

export default Preferences;
