import { ChordSymbolEnum } from "opensheetmusicdisplay";

export const sortAry = (aryOfObjs, key, order) => {
  let newAryOfObjs = aryOfObjs;
  newAryOfObjs &&
    newAryOfObjs.sort((a, b) => {
      const da = a[key];
      const db = b[key];
      let comparison = 0;
      if ((order || "asc") === "asc") {
        if (da > db) {
          comparison = 1;
        } else {
          comparison = -1;
        }
      } else {
        if (da < db) {
          comparison = 1;
        } else {
          comparison = -1;
        }
      }
      return comparison;
    });
  return newAryOfObjs;
};

export const timeToMeasure = (t, track) => (t * track.bpm) / (60 * track.meter);
export const measureToTime = (m, track) => (m * track.meter * 60) / track.bpm;
export const measureToBeat = (m, track) =>
  (m * track.meter) / (track.beat || track.beatNote || 4); //4 should be the note that's getting the beat

export const getKeyFromFifths = fifths => {
  const keyFifths = {
    C: 0,
    G: 1,
    D: 2,
    A: 3,
    E: 4,
    B: 5,
    "F#": 6,
    "C#": 7,
    F: -1,
    Bb: -2,
    Eb: -3,
    Ab: -4,
    Db: -5,
    Gb: -6,
    Cb: -7,
  };
  return Object.entries(keyFifths).filter(([i, v]) => v === fifths)[0][0];
};

export const transpositionOptions = {
  "": 0,
  "1/2 step": -5,
  "whole step": 2,
  "minor third": -3,
  "major third": 4,
  fourth: -1,
  "tri-tone": 6,
  fifth: 1,
  "minor sixth": -4,
  "major sixth": 3,
  "minor seventh": -2,
  "major seventh": 5,
};

global.transpositionOptions = transpositionOptions;

export const transpositionOptionSemitones = {
  0: 0,
  "-5": 1,
  2: 2,
  "-3": 3,
  4: 4,
  "-1": 5,
  6: 6,
  1: 7,
  "-4": 8,
  3: 9,
  "-2": 10,
  5: 11,
};

export const getElements = (e, tag, cls, elementClasses = []) => {
  let elementValues = [];
  let str = e;
  let head = "<" + tag;
  let tail = "</" + tag + ">";
  let oHead = "<" + tag + " ";
  let cHead = "<" + tag + ">";

  while (str.indexOf(oHead) + str.indexOf(cHead) >= -1 && tag !== "") {
    let thisCls = "";
    let ioh = str.indexOf(oHead);
    let ich = str.indexOf(cHead);
    if (cls === "*") {
      if (ioh === -1 || (ich < ioh && ich >= 0)) {
        thisCls = "";
      } else {
        thisCls = str.substring(
          str.indexOf(oHead) + oHead.length,
          str.indexOf(">", str.indexOf(oHead) + oHead.length)
        );
        thisCls = " " + thisCls;
      }
      elementClasses.push(thisCls);
    } else {
      thisCls = cls === "" ? "" : " " + cls;
    }
    let thisHead = head + thisCls + ">";
    str = str.substring(str.indexOf(thisHead) + thisHead.length);
    let EndOfVal = str.indexOf(tail);
    elementValues.push(str.substring(0, EndOfVal));
    str = str.substring(EndOfVal + tail.length);
  }
  return elementValues;
};

export const updateElements = (e, tag, cls, vals, elementClasses) => {
  let str = e;
  let rtn = "";
  let i = 0;
  let head = "<" + tag;
  let tail = "</" + tag + ">";
  let oHead = "<" + tag + " ";
  let cHead = "<" + tag + ">";

  while (str.indexOf(oHead) + str.indexOf(cHead) >= -1 && tag !== "") {
    let thisCls = "";
    let ioh = str.indexOf(oHead);
    let ich = str.indexOf(cHead);
    if (cls === "*") {
      if (ioh === -1 || (ich < ioh && ich >= 0)) {
        thisCls = "";
      } else {
        thisCls = str.substring(
          str.indexOf(oHead) + oHead.length,
          str.indexOf(">", str.indexOf(oHead) + oHead.length)
        );
        thisCls = " " + thisCls;
      }
    } else {
      thisCls = cls === "" ? "" : " " + cls;
    }
    let thisHead = head + thisCls + ">";
    //what if I wanted to remove the tag altogether?
    if (vals[i] === "remove tag altogether") {
      rtn += str.substring(0, str.indexOf(thisHead));
    } else if (elementClasses) {
      rtn +=
        str.substring(0, str.indexOf(thisHead)) +
        head +
        elementClasses[i] +
        ">" +
        vals[i] +
        tail;
    } else {
      rtn +=
        str.substring(0, str.indexOf(thisHead) + thisHead.length) +
        vals[i] +
        tail;
    }
    str = str.substring(str.indexOf(tail) + tail.length);
    i++;
  }
  rtn += str;
  return rtn;
};

export const scrollLeadsheet = (ls, tu) => {
  const parts = getElements(ls, "part", "*");
  const newParts = [];
  if ((tu.audioAry || "") === "") {
    return ls;
  }
  global.scrollMeasures = [];

  const tagsToRemove = [">To Coda<", ">Coda<", "<segno", ">D.S.", ">D.C."];

  for (const part of parts) {
    let newPart = part;

    // using osmd public (without the player), it seems we can keep the barlines now.
    // idealy I'd like to see the repeats and 1st/2nd endings in the scroll
    // however, it seems to mess up the cursor for now so I'm just going to dump all barlines
    let barlines = getElements(newPart, "barline", "*");
    newPart = updateElements(
      newPart,
      "barline",
      "*",
      barlines.map(() => "remove tag altogether")
    );

    let measures = getElements(newPart, "measure", "*");

    const newMeasures = [];

    const audioAry = tu.audioAry.split(",");
    for (const n of audioAry) {
      //is the first note tied to the last note of the previous measure?
      if (newMeasures.length > 0 && measures[n]) {
        let previousMeasure = newMeasures[newMeasures.length - 1];
        let previousNotes =
          previousMeasure !== undefined
            ? getElements(previousMeasure, "note", "*")
            : [];
        if (previousNotes.length > 0) {
          let lastNote = previousNotes[previousNotes.length - 1];
          let lastNoteTie =
            lastNote.indexOf("<tied type='start'/>") >= 0 ||
            lastNote.indexOf('<tied type="start"/>') >= 0
              ? true
              : false;

          // console.log("n: " + n);
          // console.log("measures[n]: " + measures[n]);
          let notes = getElements(measures[n], "note", "*");
          if (notes.length > 0) {
            let firstNote = notes.length > 0 ? notes[0] : false;

            if (lastNoteTie && firstNote) {
              let firstNoteTie =
                firstNote.indexOf("<tied type='stop'/>") >= 0 ||
                firstNote.indexOf('<tied type="stop"/>') >= 0
                  ? true
                  : false;
              if (!firstNoteTie) {
                // console.log("fix measure " + n);
                // console.log("change firstNote: " + firstNote);
                if (firstNote.indexOf("<notations>") < 0) {
                  firstNote = firstNote + "<notations></notations>";
                  // console.log("adding notations tag: " + firstNote);
                }
                firstNote = firstNote.replace(
                  "<notations>",
                  "<notations><tied type='stop'/>"
                );
                // console.log("new firstNote: " + firstNote);
                notes[0] = firstNote;
                measures[n] = updateElements(measures[n], "note", "*", notes);
              }
            }
          }
        }
      }

      if (
        Number(audioAry[0]) === 1 &&
        Number(n) === 1 &&
        newMeasures.length === 0
      ) {
        //see if the current measure has attributes
        const currentAttributes = getElements(measures[n], "attributes", "*");
        //if not, we'll use the attributes from the very first measure
        let newAttributes = getElements(measures[0], "attributes", "*");
        if (currentAttributes.length === 0) {
          measures[n] = updateElements(
            "<attributes></attributes>" + measures[n],
            "attributes",
            "*",
            newAttributes
          );
        } else {
          //if it does have attributes...
          //I want to merge the two - keep whatever is in the current but add form the first
          //key, time, staves, clef
          //assuming divisions are there already...
          newAttributes = updateElements(newAttributes[0], "divisions", "*", [
            "remove tag altogether",
          ]);
          measures[n] = updateElements(measures[n], "attributes", "*", [
            currentAttributes[0] + newAttributes,
          ]);
        }
      }
      newMeasures.push(measures[n]);
    }

    newPart = "";
    let measureCnt = 1;
    for (const newMeasureContent of newMeasures) {
      if (newMeasureContent === undefined) {
        continue;
      }
      let thisMeasureContent = newMeasureContent;
      // we need to remove the bar-style light-heavy otherwise the next measure begins on a new line
      // technically, we may only need to do this for the last measure in the leadsheet.
      if (measureCnt > 0) {
        let barStyles = getElements(thisMeasureContent, "bar-style", "*");
        let newBarStyles = [];
        for (const barStyle of barStyles) {
          let newBarStyle = barStyle;
          if (barStyle === "light-heavy") {
            newBarStyle = "light-light";
          }
          newBarStyles.push(newBarStyle);
        }
        if (newBarStyles.length > 0) {
          thisMeasureContent = updateElements(
            thisMeasureContent,
            "bar-style",
            "*",
            newBarStyles
          );
        }
        let directions = getElements(thisMeasureContent, "direction", "*");
        let newDirections = [];
        for (const direction of directions) {
          let newDirection = direction;
          let removeTag = false;
          for (const tag of tagsToRemove) {
            if (direction.indexOf(tag) >= 0) {
              removeTag = true;
              break;
            }
          }
          if (removeTag) {
            // console.log("removing direction:", direction);
            newDirection = "remove tag altogether";
          }
          newDirections.push(newDirection);
        }
        if (newDirections.length > 0) {
          thisMeasureContent = updateElements(
            thisMeasureContent,
            "direction",
            "*",
            newDirections
          );
        }
      }

      // let xml_string = xml_string_in.replace(/></g, ">\n<");

      // thisMeasureContent = thisMeasureContent.replace(/<repeat direction='forward'\/>/g, '');
      // thisMeasureContent = thisMeasureContent.replace(/<repeat direction='backward'\/>/g, '');

      if (
        Number(measureCnt) === newMeasures.filter(m => m !== undefined).length
      ) {
        thisMeasureContent += `
          <barline location='right'>
            <bar-style>light-heavy</bar-style>
          </barline>
        `;
      }

      const newMeasure =
        '<measure number="' +
        measureCnt +
        '">' +
        thisMeasureContent +
        "</measure>";
      global.scrollMeasures.push(newMeasure);
      newPart += newMeasure;
      measureCnt++;
    }

    newParts.push(newPart);
  }

  const newScroll = updateElements(ls, "part", "*", newParts);

  return newScroll;
};

export const updateFieldsFromLeadsheet = (leadsheetContent, duration) => {
  const updates = {};
  let title = getTitle(leadsheetContent);
  if (title !== "Not Found") {
    updates.title = title;
  }
  let composer = getComposer(leadsheetContent);
  if (composer !== "Not Found") {
    updates.composer = composer;
  }
  let style = getStyle(leadsheetContent);
  if (style !== "Not Found") {
    updates.style = style;
  }
  let tempo = getTempo(leadsheetContent);
  if (tempo !== "Not Found") {
    updates.tempo = tempo;
  }
  let meter = getMeter(leadsheetContent);
  if (meter !== "Not Found") {
    updates.meter = meter;
  }
  let fifths = getFifths(leadsheetContent);
  if (fifths !== "Not Found") {
    updates.fifths = fifths;
    updates.defaultKey = getKeyFromFifths(fifths);
  }

  getLsMap(updates, leadsheetContent, duration);

  // updates.pickupBar = getPickupBar(leadsheetContent, updates.meter);
  // updates.introBars = getIntroBars(leadsheetContent);
  // updates.lsTime = getLsTime(leadsheetContent);
  return updates;
};

export const getLsMap = (updates, ls, duration) => {
  const mAry = getElements(ls, "beats", "*");
  updates.meter = mAry[0];
  const bpmAry = getElements(ls, "per-minute", "*");
  updates.bpm = bpmAry[0];
  const measures = getElements(ls, "measure", "*");
  updates.introBars = 0;
  updates.lsTime = 0;
  updates.coda = 0;
  updates.toCoda = 0;
  updates.repeatBegin = [];
  updates.firstEnding = [];
  updates.repeatEnd = [];
  updates.repeatCounts = [];

  //we need to see if the first measure is a pickup measure
  //get division of first measure
  const firstBarDiv = getElements(measures[0], "divisions", "*");
  const firstBarDivs = firstBarDiv[0] * updates.meter;
  //count note durations
  const firstBarNoteDurations = getElements(measures[0], "duration", "*");
  let noteTotal = 0;
  for (let i = 0; i < firstBarNoteDurations.length; i++) {
    noteTotal = noteTotal + parseInt(firstBarNoteDurations[i]);
  }
  if (noteTotal < firstBarDivs) {
    updates.pickupBar = noteTotal / firstBarDivs;
    measures.splice(0, 1);
  } else {
    updates.pickupBar = 0;
  }

  let currentIntroBarCount = 0;
  let countIntro = false;
  let currentRepeatCount = 2;

  for (let i = 0; i < measures.length; i++) {
    currentRepeatCount = 2;
    const wordsAry = [
      ...getElements(measures[i], "words", "*"),
      ...getElements(measures[i], "text", "*"),
    ];

    const codas = getElements(measures[i], "coda", "*");

    if (codas.length > 0) {
      wordsAry.push("Coda");
    }

    for (let k = 0; k < wordsAry.length; k++) {
      if (wordsAry[k] === "Intro") {
        countIntro = true;
      }
      if (wordsAry[k] === "Head") {
        countIntro = false;
      }
      if (wordsAry[k] === "To Coda") {
        updates.toCoda = i + 1; // measure is played then you jump To Coda
      }
      if (wordsAry[k] === "Coda" || wordsAry[k] === "(*) Coda") {
        console.log("codas:", codas);
        //we're done counting measures
        updates.coda = measures.length - i;
        //oh we are are we?...
        //yeah, we're just going to have to do without repeats in a coda - ref: Old Country
        measures.splice(i);
      }
      if (wordsAry[k] === "Repeat 4x") {
        currentRepeatCount = 4;
      }
    }
    if (countIntro) {
      currentIntroBarCount++;
    }
    if (measures[i]) {
      const measureBarLines = getElements(measures[i], "barline", "*");
      if (measureBarLines.length > 0) {
        for (var j = 0; j < measureBarLines.length; j++) {
          if (measureBarLines[j].indexOf("forward") > 0) {
            updates.repeatBegin.push(i);
          }
          if (
            measureBarLines[j].indexOf("start") > 0 &&
            updates.firstEnding.length < updates.repeatBegin.length
          ) {
            updates.firstEnding.push(i);
          }
          if (measureBarLines[j].indexOf("backward") > 0) {
            updates.repeatEnd.push(i + 1);
            while (updates.firstEnding.length < updates.repeatBegin.length) {
              updates.firstEnding.push(0);
            }
            updates.repeatCounts.push(currentRepeatCount);
          }
        }
      }
    }
  }
  if (updates.firstEnding.length < updates.repeatBegin.length) {
    updates.firstEnding.push(0);
  }
  if (updates.repeatEnd.length < updates.repeatBegin.length) {
    updates.repeatEnd.push(measures.length);
  }
  if (updates.repeatCounts.length < updates.repeatBegin.length) {
    updates.repeatCounts.push(currentRepeatCount);
  }
  updates.introBars = currentIntroBarCount;

  // updates.lsTime = measures.length - updates.coda - currentIntroBarCount;
  updates.lsTime = measures.length - currentIntroBarCount;

  for (let i = 0; i < updates.repeatBegin.length; i++) {
    let repEnd = 0;
    if (updates.firstEnding[i] === 0) {
      repEnd = updates.repeatEnd[i];
    } else {
      repEnd = updates.firstEnding[i];
    }

    // I wanna try another way of doing this at some point...
    if (
      currentIntroBarCount > 0 &&
      (repEnd + (updates.repeatEnd[i] - repEnd)) / 1 <= currentIntroBarCount
    ) {
      updates.introBars = updates.introBars + (repEnd - updates.repeatBegin[i]);
      updates.introBars =
        updates.introBars +
        (updates.repeatEnd[i] - updates.repeatBegin[i]) *
          (updates.repeatCounts[i] - 2);
    } else {
      updates.lsTime = updates.lsTime + (repEnd - updates.repeatBegin[i]);
      updates.lsTime =
        updates.lsTime +
        (updates.repeatEnd[i] - updates.repeatBegin[i]) *
          (updates.repeatCounts[i] - 2);
    }
    if (updates.toCoda >= updates.repeatEnd[i]) {
      updates.toCoda = updates.toCoda + (repEnd - updates.repeatBegin[i]);
      updates.toCoda =
        updates.toCoda +
        (updates.repeatEnd[i] - updates.repeatBegin[i]) *
          (updates.repeatCounts[i] - 2);
    }

    //another way...
    // let incrementValue = (repEnd - updates.repeatBegin[i]) + ((updates.repeatEnd[i] - updates.repeatBegin[i]) *
    //   (updates.repeatCounts[i] - 2));
    // if (
    //   currentIntroBarCount > 0 &&
    //   updates.repeatEnd[i] <= currentIntroBarCount
    // ) {
    //   updates.introBars += incrementValue
    // } else {
    //   updates.lsTime += incrementValue
    // }
    // if (updates.repeatEnd[i] <= updates.toCoda) {
    //   updates.toCoda += incrementValue
    // }

    //I don't know why I'm doing this plus one business...
    // updates.lsTime = updates.lsTime + (((updates.firstEnding[i] == 0) ? (updates.repeatEnd[i] + 1) : updates.firstEnding[i]) - updates.repeatBegin[i]);
  }

  //things to think about... how do I get the audio duration in this function...
  //also... can I store an audio file in a redux store?  or in state?
  //
  //
  //
  // // we need an audio duration here...
  //meter, bpm, coda, lsTime, toCoda, introBars

  const audiobars = Math.round(
    (duration || 0) / ((updates.meter * 60) / updates.bpm)
  );
  const netCoda =
    updates.coda -
    (updates.lsTime -
      (updates.toCoda === 0
        ? updates.lsTime
        : updates.toCoda - updates.introBars));
  // // var netCoda = updates.coda - (updates.lsTime - (((updates.toCoda == 0) ? updates.lsTime : updates.toCoda) - 0));

  if (updates.introBars === 0) {
    let remainingBars = (audiobars - netCoda) % updates.lsTime;
    // assume a 2 bar coda
    remainingBars -= 2;
    remainingBars = remainingBars < 0 ? 0 : remainingBars;
    let introBars = Math.round(remainingBars);
    updates.intro = introBars;
    //assume intro is a multiple of an entire chours
    //and should be at least 20 seconds
    //unless there's an intro on the leadsheet
    while (updates.intro < 20 / ((updates.meter * 60) / updates.bpm)) {
      // ((updates.meter * 60) / updates.bpm) is seconds per measure
      updates.intro = updates.intro + updates.lsTime;
    }
  } else {
    updates.intro = updates.introBars;
  }

  updates.repeats = parseInt(
    (audiobars - (updates.intro + netCoda)) / updates.lsTime
  );

  updates.repeatBegin = updates.repeatBegin.join(",");
  updates.firstEnding = updates.firstEnding.join(",");
  updates.repeatEnd = updates.repeatEnd.join(",");
  updates.repeatCounts = updates.repeatCounts.join(",");

  //build an array with an entry for each audio bar that indicates which measure of the folded leadsheet is played
  let ac = 0;
  let audioAry = [];
  while (ac < audiobars) {
    let measure = ac;
    if (measure < updates.intro) {
      if (updates.introBars === 0) {
        measure = measure % updates.lsTime;
      }
    } else {
      //currRep should probably be renamed to something like completedRepeats
      let currRep = parseInt((measure - updates.intro) / updates.lsTime); //(143 - 16) => 127 / 32 ~ 3.9 so.. 3
      measure = (measure - updates.intro) % updates.lsTime; //127 % 32 ... 31
      if (currRep === updates.repeats) {
        measure =
          measure +
          updates.lsTime +
          (updates.toCoda > 0
            ? updates.lsTime - (updates.toCoda - updates.introBars)
            : 0);
      } else if (
        updates.toCoda > 0 && // 31
        measure >= updates.toCoda - updates.introBars && //31 > 31 - 0 => false
        currRep === updates.repeats - 1 //true
      ) {
        measure =
          measure + (updates.lsTime - (updates.toCoda - updates.introBars));
      }
      measure = measure + updates.introBars;
    }

    const currentRepeatBegin = updates.repeatBegin
      .split(",")
      .map(n => Number(n));
    const currentRepeatEnd = updates.repeatEnd.split(",").map(n => Number(n));
    const currentRepeatCounts = updates.repeatCounts
      .split(",")
      .map(n => Number(n));
    const currentFirstEnding = updates.firstEnding
      .split(",")
      .map(n => Number(n));

    for (let j = 0; j < currentRepeatBegin.length; j++) {
      let repeatLength = currentRepeatEnd[j] - currentRepeatBegin[j]; //8
      let currRepStop = currentRepeatEnd[j]; //8
      let repCnt = 1;
      if (measure > currentRepeatBegin[j]) {
        while (measure >= currRepStop && repCnt < currentRepeatCounts[j]) {
          repCnt++;
          measure = measure - repeatLength; //6
        }
        if (
          measure >= currentFirstEnding[j] &&
          repCnt === currentRepeatCounts[j] &&
          currentFirstEnding[j] > 0
        ) {
          measure = measure + (currentRepeatEnd[j] - currentFirstEnding[j]);
        }
      }
    }
    if (updates.pickupBar > 0) {
      measure++;
    }
    audioAry.push(measure);
    ac++;
  }
  updates.audioAry = audioAry.join(",");
};

const getFifths = leadsheet => {
  const elem = "fifths";
  const cls = "*";
  const instance = 0;
  const elements = getElements(leadsheet, elem, cls);
  if (elements && elements.length >= instance + 1) {
    return Number(elements[instance]);
  } else {
    return "Not Found";
  }
};

const getMeter = leadsheet => {
  const elem = "beats";
  const cls = "*";
  const instance = 0;
  const elements = getElements(leadsheet, elem, cls);
  if (elements && elements.length >= instance + 1) {
    return elements[instance];
  } else {
    return "Not Found";
  }
};

const getTitle = leadsheet => {
  const elem = "work-title";
  const cls = "*";
  const instance = 0;
  const elements = getElements(leadsheet, elem, cls);
  if (elements && elements.length >= instance + 1) {
    return elements[instance];
  } else {
    return "Not Found";
  }
};

const getComposer = leadsheet => {
  const elem = "creator";
  // const cls = "type='composer'";
  const cls = "*";
  const instance = 0;
  const elements = getElements(leadsheet, elem, cls);
  if (elements && elements.length >= instance + 1) {
    return elements[instance];
  } else {
    return "Not Found";
  }
};

const getStyle = leadsheet => {
  const elem = "direction";
  const cls = "*";
  const dirs = getElements(leadsheet, elem, cls);
  for (const dir of dirs) {
    if (dir.indexOf("</metronome>") > 0) {
      const words = getElements(dir, "words", "*");
      if (words && words.length > 0) {
        return words[0];
      }
    }
  }
  return "Not Found";
};

const getTempo = leadsheet => {
  const elem = "per-minute";
  const cls = "*";
  const instance = 0;
  const elements = getElements(leadsheet, elem, cls);
  if (elements && elements.length >= instance + 1) {
    return elements[instance].replace(".0", "") + " bpm";
  } else {
    return "Not Found";
  }
};

export const checkShow = (s, c) => {
  // console.log("checkShow", s, c);
  const elements = document.getElementsByClassName(c);
  for (const d of elements) {
    // const d = elements[0];
    if (d) {
      if (s === "toggle") {
        setTimeout(() => {
          d.classList.toggle(c + "-Show");
        }, 1);
      } else {
        if (s) {
          setTimeout(() => {
            d.classList.add(c + "-Show");
          }, 1);
        } else {
          setTimeout(() => {
            d.classList.remove(c + "-Show");
          }, 1);
        }

        // if (
        //   (s && !d.classList.contains(c + "-Show")) ||
        //   (!s && d.classList.contains(c + "-Show"))
        // ) {
        //   //need this to work on mobile.
        //   setTimeout(() => {
        //     d.classList.toggle(c + "-Show");
        //   }, 1);
        // }
      }
    }
  }
};

export const getPlayInfo = ({ track, currentTime }) => {
  let pageMeasure = timeToMeasure(currentTime, track);
  let bcTextContent = "";
  let bcTextHead = "";
  let currRepeat = "";
  if (pageMeasure <= track.intro) {
    bcTextHead = "Intro:";
    bcTextContent =
      track.intro -
      parseInt(pageMeasure) +
      "(:" +
      parseInt(
        measureToTime(track.intro, { meter: track.meter, bpm: track.bpm }) -
          measureToTime(pageMeasure, { meter: track.meter, bpm: track.bpm })
      ) +
      ")";
    if (track.introBars === 0) {
      pageMeasure = pageMeasure % track.lsTime;
    }
  } else {
    let currRep = parseInt((pageMeasure - track.intro) / track.lsTime);
    pageMeasure = (pageMeasure - track.intro) % track.lsTime;
    if (currRep === track.repeats) {
      bcTextHead = "Coda";
      bcTextContent = "";
      pageMeasure =
        pageMeasure +
        track.lsTime +
        (track.toCoda > 0
          ? track.lsTime - (track.toCoda - track.introBars)
          : 0);
    } else if (
      track.toCoda > 0 &&
      pageMeasure > track.toCoda - track.introBars &&
      currRep === track.repeats - 1
    ) {
      bcTextHead = "Coda";
      bcTextContent = "";
      pageMeasure =
        pageMeasure + (track.lsTime - (track.toCoda - track.introBars));
    } else {
      currRepeat = "" + (currRep + 1);
    }
    pageMeasure = pageMeasure + track.introBars;
  }
  let currentSubRepeat = "";
  const currentRepeatBegin = track.repeatBegin.split(",").map(n => Number(n));
  const currentRepeatEnd = track.repeatEnd.split(",").map(n => Number(n));
  const currentRepeatCounts = track.repeatCounts.split(",").map(n => Number(n));
  const currentFirstEnding = track.firstEnding.split(",").map(n => Number(n));

  for (let j = 0; j < currentRepeatBegin.length; j++) {
    let repeatLength = currentRepeatEnd[j] - currentRepeatBegin[j]; //8
    let currRepStop = currentRepeatEnd[j]; //8
    let repCnt = 1;
    if (pageMeasure > currentRepeatBegin[j]) {
      currentSubRepeat = "";
      while (pageMeasure > currRepStop && repCnt < currentRepeatCounts[j]) {
        repCnt++;
        pageMeasure = pageMeasure - repeatLength;
        currentSubRepeat = "(" + repCnt + ")";
      }

      if (
        pageMeasure > currentFirstEnding[j] &&
        repCnt === currentRepeatCounts[j] &&
        currentFirstEnding[j] > 0
      ) {
        pageMeasure =
          pageMeasure + (currentRepeatEnd[j] - currentFirstEnding[j]);
        currentSubRepeat = "";
      }
    }
  }
  if (bcTextHead === "") {
    bcTextHead = "Repeats:";
    bcTextContent = currRepeat + currentSubRepeat + "/" + track.repeats;
  }
  pageMeasure += track.pickupBar;
  return {
    bcTextHead,
    bcTextContent,
    pageMeasure,
  };
};

export const getPosAry = osmd => {
  const posAry = [];
  osmd.cursor.reset();
  const iterator = osmd.cursor.iterator;
  let maxTimeStamp = -1;
  while (!iterator.endReached) {
    if (iterator.currentTimeStamp.realValue > maxTimeStamp) {
      maxTimeStamp = iterator.currentTimeStamp.realValue;
      posAry.push(iterator.currentTimeStamp.realValue);
    }
    iterator.moveToNext();
  }
  // console.log(posAry);
  return posAry;
};

export const setCursorPos = ({ posAry, currentBeatIndex, osmd }) => {
  if (!(posAry || []).length) return;
  if (!osmd) return;
  if (!osmd.cursor) return;
  if (!osmd.cursor.iterator) return;
  let currentBeat = posAry[currentBeatIndex];
  let cursorBeat = osmd.cursor.iterator.currentTimeStamp.realValue;
  if (cursorBeat > currentBeat) {
    osmd.cursor.reset();
    cursorBeat = osmd.cursor.iterator.currentTimeStamp.realValue;
  }
  let tryCount = 0;
  while (cursorBeat < currentBeat && tryCount < 1000) {
    osmd.cursor.next();
    cursorBeat = osmd.cursor.iterator.currentTimeStamp.realValue;
    tryCount++;
  }
  return;
};

//can I write a function to just get the pageMeasure
export const getPageMeasure = ({ measure, track }) => {
  let pageMeasure = measure;
  if (pageMeasure <= track.intro) {
    if (track.introBars === 0) {
      pageMeasure = pageMeasure % track.lsTime;
    }
  } else {
    pageMeasure = (pageMeasure - track.intro) % track.lsTime;
    let currRep = parseInt((pageMeasure - track.intro) / track.lsTime);
    if (currRep === track.repeats) {
      pageMeasure =
        pageMeasure +
        track.lsTime +
        (track.toCoda > 0
          ? track.lsTime - (track.toCoda - track.introBars)
          : 0);
    } else if (
      track.toCoda > 0 &&
      pageMeasure > track.toCoda - track.introBars &&
      currRep === track.repeats - 1
    ) {
      pageMeasure =
        pageMeasure + (track.lsTime - (track.toCoda - track.introBars));
    }
    pageMeasure = pageMeasure + track.introBars;
  }
  const currentRepeatBegin = track.repeatBegin.split(",").map(n => Number(n));
  const currentRepeatEnd = track.repeatEnd.split(",").map(n => Number(n));
  const currentRepeatCounts = track.repeatCounts.split(",").map(n => Number(n));
  const currentFirstEnding = track.firstEnding.split(",").map(n => Number(n));
  for (let j = 0; j < currentRepeatBegin.length; j++) {
    let repeatLength = currentRepeatEnd[j] - currentRepeatBegin[j]; //8
    let currRepStop = currentRepeatEnd[j]; //8
    let repCnt = 1;
    if (pageMeasure > currentRepeatBegin[j]) {
      while (pageMeasure > currRepStop && repCnt < currentRepeatCounts[j]) {
        repCnt++;
        pageMeasure = pageMeasure - repeatLength;
      }

      if (
        pageMeasure > currentFirstEnding[j] &&
        repCnt === currentRepeatCounts[j] &&
        currentFirstEnding[j] > 0
      ) {
        pageMeasure =
          pageMeasure + (currentRepeatEnd[j] - currentFirstEnding[j]);
      }
    }
  }
  pageMeasure += track.pickupBar;
  return pageMeasure;
};

export const getMeasureWidths = (osmd, zoom) => {
  let mw = osmd.graphic.measureList.map(
    e =>
      e[0] && e[0].PositionAndShape && e[0].PositionAndShape.Size.width * zoom
  );
  return mw;
};

export const getIncidentalMeters = lsc => {
  const lscms = getElements(lsc, "measure", "*");
  const meter = getMeter(lsc);
  console.log("current meter:", meter);
  let incidentalMeters = [];
  for (let i = 0; i < lscms.length; i++) {
    let mm = getMeter(lscms[i]);
    if (mm !== "Not Found" && mm !== meter) {
      console.log("measure index " + i + " not current meter", mm, meter);
      incidentalMeters.push({ measureIndex: i, meter: Number(mm) });
    }
  }
  return incidentalMeters;
};

export const getLeadMeasure = (
  scrollMeasure,
  incidentalMeters,
  currentMeter,
  beatType
) => {
  let leadMeasure = scrollMeasure;

  for (const im of incidentalMeters) {
    if (leadMeasure > im.measureIndex) {
      const adj = currentMeter / im.meter;
      const pIn = (leadMeasure - im.measureIndex) * adj;
      if (pIn < 1) {
        leadMeasure = im.measureIndex + pIn;
      } else {
        leadMeasure += currentMeter * beatType - im.meter * beatType;
        // leadMeasure += 1 / (adj);
      }
    }
  }

  return leadMeasure;
};

export const updateOSMD = osmd => {
  osmd.EngravingRules.AutoGenerateMutipleRestMeasuresFromRestMeasures = false;
  osmd.rules.setChordSymbolLabelText(ChordSymbolEnum.minor, "-");
  osmd.rules.setChordSymbolLabelText(ChordSymbolEnum.augmented, "+");
  osmd.rules.setChordSymbolLabelText(ChordSymbolEnum.diminished, "o");
  osmd.rules.setChordSymbolLabelText(ChordSymbolEnum.majorseventh, "Δ7");
  osmd.rules.setChordSymbolLabelText(ChordSymbolEnum.minorseventh, "-7");
  osmd.rules.setChordSymbolLabelText(ChordSymbolEnum.diminishedseventh, "o7");
  osmd.rules.setChordSymbolLabelText(ChordSymbolEnum.augmentedseventh, "+7");
  osmd.rules.setChordSymbolLabelText(ChordSymbolEnum.halfdiminished, "ø7");
  osmd.rules.setChordSymbolLabelText(ChordSymbolEnum.majorminor, "−Δ");
  osmd.rules.setChordSymbolLabelText(ChordSymbolEnum.majorsixth, "Δ6");
  osmd.rules.setChordSymbolLabelText(ChordSymbolEnum.minorsixth, "-6");
  osmd.rules.setChordSymbolLabelText(ChordSymbolEnum.majorninth, "Δ9");
  osmd.rules.setChordSymbolLabelText(ChordSymbolEnum.minorninth, "-9");
  osmd.rules.setChordSymbolLabelText(ChordSymbolEnum.major11th, "Δ11");
  osmd.rules.setChordSymbolLabelText(ChordSymbolEnum.minor11th, "-11");
  osmd.rules.setChordSymbolLabelText(ChordSymbolEnum.major13th, "Δ13");
  osmd.rules.setChordSymbolLabelText(ChordSymbolEnum.minor13th, "-13");
  //custom chords
  osmd.rules.renameChord("m(maj9)", "−Δ9");
  osmd.rules.renameChord("m69", "−69");
  osmd.rules.renameChord("m(maj11)", "−Δ11");
  osmd.rules.renameChord("m(maj13)", "−Δ13");
  osmd.rules.addChordName("ø7", "minorseventh", ["b5"], [], []);
  return osmd;
};

export const roughSizeOfObject = object => {
  var objectList = [];
  var stack = [object];
  var bytes = 0;

  while (stack.length) {
    var value = stack.pop();

    if (typeof value === "boolean") {
      bytes += 4;
    } else if (typeof value === "string") {
      bytes += value.length * 2;
    } else if (typeof value === "number") {
      bytes += 8;
    } else if (typeof value === "object" && objectList.indexOf(value) === -1) {
      if (value.size) {
        bytes += value.size;
      } else {
        objectList.push(value);

        for (var i in value) {
          stack.push(value[i]);
        }
      }
    }
  }
  return bytes;
};

//number
export const getMegsOfObject = object => {
  const bytes = roughSizeOfObject(object);
  const megs = bytes / 1000000;
  return Math.round(megs);
};
