import React from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  useFirestoreConnect,
  useFirestore,
  isLoaded,
  useFirebase,
} from "react-redux-firebase";
import { v4 as uuidv4 } from "uuid";

const handleClick = e => {
  const header = e.target.closest("div");
  if (header.className.indexOf("features-header") >= 0) {
    // console.log("yeah");
    header.classList.toggle("features-active");
    const content = header.nextElementSibling;
    // console.log(content.scrollHeight);
    if (content.style.display === "block") {
      content.style.display = "none";
    } else {
      content.style.display = "block";
      const elems = content.getElementsByTagName("textarea");
      for (const elem of elems) {
        elem.style.height = "1px";
        elem.style.height = 25 + elem.scrollHeight + "px";
      }
    }
  }
};

const expandAll = () => {
  const headers = document.getElementsByClassName("features-header");
  for (const header of headers) {
    header.classList.toggle("features-active");
  }
  const contents = document.getElementsByClassName("features-content");
  for (const content of contents) {
    if (content.style.display === "block") {
      content.style.display = "none";
    } else {
      content.style.display = "block";
      const elems = content.getElementsByTagName("textarea");
      for (const elem of elems) {
        elem.style.height = "1px";
        elem.style.height = 25 + elem.scrollHeight + "px";
      }
    }
  }
};

const updateNewData = (
  data,
  mAry,
  value,
  assets,
  storage,
  firestore,
  docName
) => {
  // console.log(mAry, data);
  let i = mAry[0];
  if (mAry.length === 1) {
    switch (value) {
      case "[[DELETE]]":
        //are there any files to delete?
        const strData = JSON.stringify(data[i]);
        for (const asset of assets) {
          if (strData.indexOf(asset) >= 0) {
            console.log("remove asset", asset);
            storage.ref(`documentAssets/${docName}/${asset}`).delete();
            let newAssets = [...assets];
            if (newAssets.indexOf(asset) >= 0) {
              newAssets.splice(newAssets.indexOf(asset), 1);
              console.log("update asset list", newAssets);
              firestore
                .collection("documents")
                .doc(docName)
                .update({ assets: newAssets });
            }
          }
        }
        data.splice(i, 1);
        break;
      case "[[INSERT]]":
        if (data[i].content !== undefined) {
          data[i].content.push("");
        } else {
          data[i] = { header: data[i], content: [""] };
        }
        break;
      case "[[MOVEUP]]":
        if (i > 0) {
          const hold = data[i - 1];
          data[i - 1] = data[i];
          data[i] = hold;
        }
        break;
      case "[[MOVEDOWN]]":
        if (i < data.length - 1) {
          const hold = data[i + 1];
          data[i + 1] = data[i];
          data[i] = hold;
        }
        break;
      default:
        if (data[i].header !== undefined) {
          data[i].header = value;
        } else {
          data[i] = value;
        }
        break;
    }
  } else {
    let m = [...mAry];
    m.splice(0, 1);
    data[i].content = updateNewData(
      data[i].content,
      m,
      value,
      assets,
      storage,
      firestore,
      docName
    );
    if (mAry.length === 2 && value === "[[DELETE]]") {
      if (data[i].content.length === 0) {
        data[i] = data[i].header;
      }
    }
  }
  return data;
};

const loadAssetUrls = async ({
  storage,
  assets,
  assetUrls,
  setAssetUrls,
  docName,
}) => {
  const au = JSON.parse(assetUrls);
  for (const asset of assets) {
    if (au[asset] === undefined) {
      console.log(asset);
      try {
        au[asset] = await storage
          .ref(`documentAssets/${docName}/${asset}`)
          .getDownloadURL();
      } catch (error) {
        console.error(error);
      }
    }
  }
  const newAssetUrls = JSON.stringify(au);
  setAssetUrls(newAssetUrls);
};

const fileInput = React.createRef();

const About = props => {
  const withTutorial = props.withTutorial;
  const setWithTutorial = props.setWithTutorial;
  const withSecondApp = props.withSecondApp;
  const setWithSecondApp = props.setWithSecondApp;
  const profile = useSelector(state => state.firebase.profile);
  const roles = [
    ...((profile &&
      profile.token &&
      profile.token.claims &&
      Object.keys(profile.token.claims).filter(
        k => profile.token.claims[k] === true
      )) ||
      []),
    "anyone",
  ];

  useFirestoreConnect({
    collection: `documents`,
    where: [["canView", "array-contains-any", roles]],
    storeAs: "documents",
  });

  // const docName = "Help";
  const history = useHistory();
  const docName =
    (props.match && props.match.params.docName) || props.docName || "About";
  const setDocName = d => {
    history.push(`/${d}${window.location.search}`);
  };

  const docs = useSelector(state => state.firestore.data.documents);

  // const [docName, setDocName] = React.useState("About");
  // const seedData = (docs && docs[docName] && docs[docName].content) || [];
  const canEdit = (docs && docs[docName] && docs[docName].canEdit) || [];

  const [editMode, setEditMode] = React.useState(false);
  // const [data, setData] = React.useState(JSON.stringify(seedData));
  const [data, setData] = React.useState("[]");
  // const [savedData, setSavedData] = React.useState(JSON.stringify(seedData));
  const [savedData, setSavedData] = React.useState("[]");

  const firebase = useFirebase();
  const storage = firebase.storage();

  const [assets, setAssets] = React.useState([]);

  const [assetUrls, setAssetUrls] = React.useState("{}");

  React.useEffect(() => {
    const seedAssets = (docs && docs[docName] && docs[docName].assets) || [];
    // setAssets(JSON.stringify(seedAssets))
    if (seedAssets.length > 0) {
      setAssets(seedAssets);
      loadAssetUrls({ storage, assets, assetUrls, setAssetUrls, docName });
    }
  }, [assetUrls, assets, docName, docs, storage]);

  React.useEffect(() => {
    if (assets && assets.length > 0) {
      // console.log(assets);
      // console.log("load assetUrls here?");
      loadAssetUrls({ storage, assets, assetUrls, setAssetUrls, docName });
    }
  }, [assetUrls, assets, docName, storage]);

  React.useEffect(() => {
    if (editMode) {
      let contents = document.getElementsByClassName("features-content");
      if (contents.length === 0) {
        const elem = document.getElementsByTagName("textarea")[0];
        console.log(elem);
        elem.style.height = "1px";
        elem.style.height = 25 + elem.scrollHeight + "px";
      } else {
        for (const content of contents) {
          if (content.style.display === "block") {
            const elems = content.getElementsByTagName("textarea");
            for (const elem of elems) {
              elem.style.height = "1px";
              elem.style.height = 25 + elem.scrollHeight + "px";
            }
          }
        }
      }
    }
  }, [editMode]);

  // React.useEffect(() => {
  //   setData(JSON.stringify(seedData));
  //   setSavedData(JSON.stringify(seedData));
  // }, [seedData]);

  React.useEffect(() => {
    const seedData = (docs && docs[docName] && docs[docName].content) || [];
    setData(JSON.stringify(seedData));
    setSavedData(JSON.stringify(seedData));
  }, [docName, docs]);

  const handleChange = e => {
    const elem = e.target;
    elem.style.height = "1px";
    elem.style.height = 25 + elem.scrollHeight + "px";
    const mAry = elem
      .getAttribute("m")
      .split(",")
      .map(v => Number(v));
    const newValue = elem.value;
    const newData = updateNewData(
      JSON.parse(data),
      mAry,
      newValue,
      assets,
      storage,
      firestore,
      docName
    );

    setData(JSON.stringify(newData));
  };

  const handleDelete = m => {
    console.log("delete:", m);
    const newData = updateNewData(
      JSON.parse(data),
      m,
      "[[DELETE]]",
      assets,
      storage,
      firestore,
      docName
    );
    setData(JSON.stringify(newData));
  };

  const [openMe, setOpenMe] = React.useState("");

  React.useEffect(() => {
    if (openMe && openMe !== "" && data) {
      const hcls = "fhid-" + openMe.join(",");
      const headers = document.getElementsByClassName(hcls);
      for (const header of headers) {
        if (!header.classList.contains("features-active")) {
          header.classList.toggle("features-active");
        }
      }
      const ccls = "fcid-" + openMe.join(",");
      const contents = document.getElementsByClassName(ccls);
      for (const content of contents) {
        content.style.display = "block";
      }
      setOpenMe("");
    }
  }, [openMe, data]);

  React.useEffect(() => {
    if (data) {
      if (data.indexOf("<img>") >= 0) {
        console.log("adding image");
        fileInput.current.click();
      }
    }
  }, [data]);

  const [uploadProgress, setUploadProgress] = React.useState(0);

  const handleFileSelection = () => {
    const file = fileInput.current.files[0];
    let newData = data;
    if (file) {
      let uuid = uuidv4();
      let saveAsfn = `${uuid}-${file.name}`;
      newData = newData.replace("<img>", saveAsfn);
      const fileSize = file.size;
      const asRef = storage.ref(`documentAssets/${docName}/${saveAsfn}`);
      const asTask = asRef.put(file);
      asTask.on(
        "state_changed",
        ss => updateUploadProgress(ss, fileSize),
        uploadErrHndlr,
        () => {
          setUploadProgress(0);
          let newAssets = [...assets];
          if (newAssets.indexOf(saveAsfn) < 0) {
            newAssets.push(saveAsfn);
            firestore
              .collection("documents")
              .doc(docName)
              .update({ assets: newAssets })
              .then(() => {
                setData(newData);
              });
          } else {
            setData(newData);
          }
        }
      );
    }
  };

  const updateUploadProgress = (snapshot, fileSize) => {
    let percentage = (snapshot.bytesTransferred / fileSize) * 100;
    setUploadProgress(percentage);
  };

  const uploadErrHndlr = err => {
    console.error("Error uploading file: " + JSON.stringify(err));
  };

  const handleInsert = (m, e) => {
    console.log("insert:", m);
    const newData = updateNewData(
      JSON.parse(data),
      m,
      "[[INSERT]]",
      assets,
      storage,
      firestore,
      docName
    );
    setOpenMe(m);
    setData(JSON.stringify(newData));
  };

  const firestore = useFirestore();

  const handleSave = () => {
    const newDoc = JSON.parse(data);
    firestore.collection("documents").doc(docName).update({ content: newDoc });
  };

  const moveItemUp = m => {
    console.log("move up:", m);
    const newData = updateNewData(
      JSON.parse(data),
      m,
      "[[MOVEUP]]",
      assets,
      storage,
      firestore,
      docName
    );
    setData(JSON.stringify(newData));
  };
  const moveItemDown = m => {
    console.log("move down:", m);
    const newData = updateNewData(
      JSON.parse(data),
      m,
      "[[MOVEDOWN]]",
      assets,
      storage,
      firestore,
      docName
    );
    setData(JSON.stringify(newData));
  };

  const [selectMode, setSelectMode] = React.useState({});

  const changeIndex = m => {
    const { start, end } = selectMode;
    if (start === undefined && end === undefined && m[m.length - 1] > 0) {
      setSelectMode({ start: m });
    } else if (
      start !== undefined &&
      end === undefined &&
      m.length === selectMode.start.length &&
      m[m.length - 1] >= selectMode.start[selectMode.start.length - 1]
    ) {
      setSelectMode({ ...selectMode, end: m });
    } else if (
      start !== undefined &&
      end !== undefined &&
      m.length === selectMode.start.length &&
      m[m.length - 1] < selectMode.start[selectMode.start.length - 1]
    ) {
      const target = m;
      const move = start[start.length - 1] - target[target.length - 1];
      const prefix = [...target];
      prefix.splice(prefix.length - 1, 1);
      console.log(start, end, target, move, prefix);
      let newData = data;
      for (let i = start[start.length - 1]; i <= end[end.length - 1]; i++) {
        let c = i;
        for (let j = 0; j < move; j++) {
          console.log("move", [...prefix, c], " up one");

          newData = JSON.stringify(
            updateNewData(
              JSON.parse(newData),
              [...prefix, c],
              "[[MOVEUP]]",
              assets,
              storage,
              firestore,
              docName
            )
          );

          c--;
        }
      }

      setData(newData);

      setSelectMode({});
    } else {
      setSelectMode({});
    }
  };

  const MyElem = v => {
    // return v;
    let t = v;
    const au = JSON.parse(assetUrls);
    for (const asset of assets || []) {
      if (t && t.indexOf(asset) >= 0) {
        if (au[asset] !== undefined) {
          let newImageTag = `<span class="for-image"><img src="${au[asset]}" /></span>`;
          t = t.replace(asset, newImageTag);
        }
      }
    }
    return <span dangerouslySetInnerHTML={{ __html: t }}></span>;
  };

  const MenuItemControl = (v, m, t) => {
    return (
      <>
        {editMode ? (
          <div className="Menu-Item-Control">
            <div className="Menu-Item-Content">
              {t === "input" ? (
                <input
                  type="text"
                  value={v}
                  onChange={handleChange}
                  m={[...m]}
                />
              ) : (
                <textarea
                  type="text"
                  value={v}
                  onChange={handleChange}
                  m={[...m]}
                />
              )}
            </div>
            <div className="Menu-Item-Buttons">
              <button
                className="App-div-button small-button"
                onClick={() => handleDelete([...m])}
              >
                <div>&times;</div>
              </button>
              <button
                className="App-div-button small-button"
                onClick={() => handleInsert([...m])}
              >
                <div>+</div>
              </button>
              <button className="App-div-button" onClick={() => moveItemUp(m)}>
                <div>&uarr;</div>
              </button>
              <button
                className="App-div-button"
                onClick={() => moveItemDown(m)}
              >
                <div>&darr;</div>
              </button>
              <button className="App-div-button" onClick={() => changeIndex(m)}>
                {m[m.length - 1]}
              </button>
            </div>
          </div>
        ) : (
          MyElem(v)
        )}
      </>
    );
  };

  const MenuItem = (o, i, m = []) => {
    const dataLength = JSON.parse(data).length || 0;
    return (
      <div key={i}>
        {dataLength === 1 ? (
          MenuItemControl(o.header, [...m, i], "textarea")
        ) : (
          <>
            <div className={`features-header fhid-${[...m, i].join(",")}`}>
              {MenuItemControl(o.header, [...m, i], "input")}
            </div>
            <div className={`features-content fcid-${[...m, i].join(",")}`}>
              {o.content &&
                o.content.map((c, s) => (
                  <div key={s} className="step">
                    <div className="left">
                      {c.header === undefined
                        ? MenuItemControl(c, [...m, i, s], "textarea")
                        : MenuItem(c, s, [...m, i])}
                    </div>
                  </div>
                ))}
            </div>
          </>
        )}
      </div>
    );
  };

  const AddNewMenuItem = () => {
    return (
      <div
        className="features-add"
        onClick={() => {
          setData(d => {
            const da = JSON.parse(d);
            da.push({ header: "", content: [] });
            return JSON.stringify(da);
          });
        }}
      >
        Add New
      </div>
    );
  };

  if (!isLoaded(docs)) return <>Loading...</>;

  return (
    <div className="Fixed-Width-Container">
      <div className="Fixed-Width-Content">
        {(!props.hideTitle || docName === "Tutorial") && (
          <h2 style={{ marginBottom: "0" }}>{docName}</h2>
        )}
        {editMode && <div>{JSON.stringify(selectMode)}</div>}
        <div className="features" onClick={handleClick}>
          {!props.hideTitle && //if not hiding title
          docName === "Tutorial" && //docname is tutorial
          !canEdit.some(r => roles.includes(r)) ? ( //and this person can't edit we just show the first content of the doc.
            <>
              {JSON.parse(data)
                .filter((o, i) => i === 0)
                .map((o, i) => MenuItem(o, i))}
            </>
          ) : (
            <>
              {docName === "Tutorial" && props.hideTitle ? (
                <>
                  {JSON.parse(data)
                    .filter((o, i) => i > 0)
                    .map((o, i) => MenuItem(o, i))}
                </>
              ) : (
                <>{JSON.parse(data).map((o, i) => MenuItem(o, i))}</>
              )}
            </>
          )}
          {editMode && AddNewMenuItem()}
        </div>
        {docName === "Tutorial" && (
          <>
            {withTutorial && (
              <button
                className="App-navbutton"
                onClick={() => {
                  setWithSecondApp(wsa => !wsa);
                }}
              >
                {withSecondApp ? "Hide Second App" : "Show Second App"}
              </button>
            )}
            <button
              className="App-navbutton"
              onClick={() => {
                setWithTutorial(wt => !wt);
                if (props.history) {
                  props.history.push("/" + window.location.search);
                }
              }}
            >
              {withTutorial ? "Hide Tutorial" : "Show App"}
            </button>
            <div>&nbsp;</div>
            <div>&nbsp;</div>
          </>
        )}
        {!props.hideTitle && (
          <>
            <div>
              <input
                type="file"
                ref={fileInput}
                name="fileInput"
                onChange={handleFileSelection}
                multiple
                hidden
                // accept="audio/*,text/xml,.musicxml"
              />
            </div>
            <div>
              {uploadProgress > 0 && (
                <span>
                  Uploading New Asset - {uploadProgress.toFixed()}% complete
                </span>
              )}
            </div>
            {canEdit.some(r => roles.includes(r)) && (
              <>
                <div>
                  <button
                    className="App-navbutton"
                    onClick={() => {
                      setEditMode(em => !em);
                    }}
                  >
                    Edit
                  </button>
                </div>
                <div>
                  <button className="App-navbutton" onClick={expandAll}>
                    Expand / Collapse
                  </button>
                </div>
                <div>
                  <button
                    className="App-navbutton"
                    onClick={handleSave}
                    disabled={data === savedData}
                  >
                    Save
                  </button>
                </div>
              </>
            )}
            {Object.keys(docs).map((d, i) => {
              return (
                <div key={i}>
                  <button
                    className="App-navbutton"
                    onClick={() => setDocName(d)}
                    disabled={d === docName}
                  >
                    {d}
                  </button>
                </div>
              );
            })}
            <div>
              <button
                className="App-navbutton"
                onClick={() => props.history.push("/" + window.location.search)}
              >
                Home
              </button>
            </div>
            <div>&nbsp;</div>
          </>
        )}
      </div>
    </div>
  );
};

export default About;
